export default {
  name: 'INPUTBUTTON',
  props: {
    text: {
      type: String,
      default: '搜索'
    }
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {}
};