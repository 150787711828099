import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios' // 如果需要axios，请引入
import Vant from 'vant'
import {  ActionSheet } from 'vant';

import './assets/main.css'
// import '@/icons/h5/iconfont'

// 导入全局样式
import '@/styles/index.scss'
import '@/icons'

// vant需要单独引入的样式
import 'vant/lib/index.css'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/image-preview/style'
import $global from './utils/global.js'
import IndexedDBHelp from './api/dexie-indexeddb'
// import SvgIcon from '@/components/SvgIcon.vue'
// import CpNavBar from '@/components/cp-nav-bar.vue'
// import H5Icon from '@/components/H5Icon.vue'
import UI from '@/components'



const app = createApp(App)

app.use(store)
app.use(router)
app.use(UI)
// app.use(Toast)
app.use(ActionSheet);
// app.component(SvgIcon)
// app.component(CpNavBar)
// app.component(H5Icon)
app.use(Vant)
app.config.globalProperties.$global = $global

// IndexedDB数据库服务
const dbHelper = new IndexedDBHelp()
app.config.globalProperties.dbHelper = dbHelper

window.dbHelper = dbHelper
window.$global = $global

/* 注意：如果你的项目中有使用axios，请用下面一行代码将全局axios复位为你的axios！！ */
window.axios = axios

app.mount('#app')
