import "core-js/modules/es.array.push.js";
import router from '@/router';
export default {
  name: 'H5NavBar',
  props: {
    LeftArrow: {
      type: Boolean,
      default: true
    },
    LeftText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    back: Function
  },
  data() {
    return {
      border: true
    };
  },
  mounted() {
    // console.log(this.$props.title)
  },
  methods: {
    // 一定有的功能：返回图标，返回效果，固定定位（组件内部实现）
    onClickRight() {
      this.$emit('right-text');
    },
    // 使用组件时候才能确定的功能：标题，右侧文字，点击右侧文字行为（props传入）
    onClickLeft() {
      if (this.back) return this.back();
      if (history.state.back) {
        router.back();
      } else {
        router.push('/');
      }
    }
  }
};