import "core-js/modules/es.array.push.js";
export default {
  name: 'H5CELL',
  props: {
    src: {
      type: String,
      default: 'https://img01.yzcdn.cn/vant/cat.jpeg'
    },
    name: {
      type: String,
      default: '张三feng'
    },
    to: {
      type: [Object, String],
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    jump() {
      if (this.to) {
        this.$nextTick(() => {
          this.$router.push(this.to);
        });
      }
    }
  }
};