import { createStore } from 'vuex'

import User from './module/User'
import Chat from './module/Chat'
import Config from './module/Config'

const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    User,
    Chat,
    Config
  }
})

// 监听聊天列表的值， 发生变化就保存在localStorage中
store.watch(
  (state) => state.Chat.chatlist,
  (val) => {
    localStorage.setItem('vue-chat', JSON.stringify(val))
  },
  {
    deep: true
  }
)

export default store
