import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a4021018"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "img"
};
const _hoisted_2 = {
  class: "cell"
};
const _hoisted_3 = {
  class: "cell_title"
};
const _hoisted_4 = {
  class: "custom-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", {
    class: "h5cell",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.jump && $options.jump(...args))
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_image, {
    radius: "3.5",
    fit: "cover",
    width: "30",
    height: "30",
    src: $props.src
  }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString($props.name), 1)])])]);
}