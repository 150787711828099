export default {
  name: 'cp-icon',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#000'
    }
  }
};