import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6cfaca07"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["fill"];
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    "aria-hidden": "true",
    class: "cp-icon",
    fill: $props.color
  }, [_createElementVNode("use", {
    href: `#icon-${$props.name}`
  }, null, 8, _hoisted_2)], 8, _hoisted_1);
}