import {getChatId, isEmpty} from "@/utils/StringUtils";

export default {
    ws: {},
    wsUrl: 'wss://kj.isuger.com.cn/wsdev/',
    userInfo: null,
    onlineUsers: [],
    store: null,

    pcList: [], // rtc连接列表
    localStream: null,
    roomName: 666,
    userName: null,
    myStreamId: null,
    myTarget: null,

    // 侦听回调
    webrtcCallback: [],
    // 重连回调
    reconnectCallback: null,

    // 重连
    lockReconnect: false,
    data: {limit: 0},
    timer: null,
    isReconnect: false,

    // 检查用户在线情况
    maxOnlineTimes: 0,

    heartCheck: {
        timeout: 10000,
        timeoutObj: null,
        serverTimeoutObj: null,
        reset: function () {
            clearTimeout(this.timeoutObj);
            clearTimeout(this.serverTimeoutObj);
            return this;
        },
        start: function (ws, userId) {
            this.timeoutObj = setTimeout(() => {
                const sendJson = JSON.stringify({
                    type: 'ping',
                    userId: userId,
                    time: Date.now(),
                });

                ws.send(sendJson);

                this.serverTimeoutObj = setTimeout(() => {
                    ws.close();
                }, this.timeout);
            }, this.timeout);
        }
    },

    connect(userInfo, store) {
        this.isReconnect = true;
        this.userInfo = userInfo;
        this.init();
        this.store = store;
    },
    close() {
        try {
            this.isReconnect = false;

            this.heartCheck.reset();

            if (this.ws != null) {
                this.ws.close();
                this.ws = null;
            }
        } catch (e) {
        }
    },
    setRoomId(roomId) {
        this.roomName = roomId;
    },
    setCallback(type, callback) {
        // type=0:聊天界面 type=2:会议界面
        this.webrtcCallback[type] = callback;
    },
    getWs() {
        return this.ws;
    },
    checkOnline() {
        this.maxOnlineTimes++;
        if(this.maxOnlineTimes>3) {
            this.maxOnlineTimes = 0;

            let userList = this.store.getters['getChatlist'];

            if(userList!=null && userList.length>0) {
                let ids = [];
                userList.forEach(r=> {
                    if(r.userId!=this.userInfo.id)
                        ids.push(r.userId);
                })


                this.ws.send(JSON.stringify({
                    type: "checkOnline",
                    userId: this.userInfo.id,
                    userIds: ids.join(','),
                }));
            }
        }
    },
    init() {
        this.ws = new WebSocket(this.wsUrl);
        this.ws.onopen = (evt) => {
            console.log(evt)
            console.log("connent WebSocket is ok");
            this.userInfo.messages = [];
            const sendJson = JSON.stringify({
                type: "conn",
                userId: this.userInfo.id,
                chatId: getChatId(0, this.userInfo.id),
                userInfo: this.userInfo,
            });
            this.ws.send(sendJson); // 注册用户名
        };
        this.ws.onmessage = (msg) => {
            const str = msg.data.toString();
            const json = JSON.parse(str);
            // console.log('===============onmessage===============')
            // console.log(str)
            switch (json.type) {
                case "conn":
                    console.log("连接成功");
                    this.setData(0);

                    this.heartCheck.reset().start(this.ws, this.userInfo.id)

                    this.store.commit("setUser", this.userInfo);
                    this.addOnlineUser(this.userInfo);
                    this.getOnlineUsers();

                    if (this.reconnectCallback != null && this.reconnectCallback.callback != null) {
                        this.reconnectCallback.callback();
                    }
                    break;
                case 'ping':
                    this.heartCheck.reset().start(this.ws, this.userInfo.id);
                    this.checkOnline();
                    break;
                case 'kickLogin':
                    if (this.webrtcCallback != null && this.webrtcCallback[99] != null) {
                        this.webrtcCallback[99](json);
                    }
                    break;
                case "online":
                    this.addOnlineUser(json.userInfo);
                    break;
                case "getOnline":
                    this.setOnlineUsers(json);
                    break;
                case "getOffline":
                    this.getUserOffline(json);
                    break;
                case "sendResponse":
                    this.sendResponse(json);
                    break;
                case 'send':
                    this.receiveMsg(json);
                    break;
                case 'notify':
                    console.log(json)
                    console.log(this.webrtcCallback)
                    if (json.cmdType == 99) {
                        // 群/会议通知消息
                        if (this.webrtcCallback != null && this.webrtcCallback[99] != null) {
                            this.webrtcCallback[99](json);
                        }
                    }
                    else if (json.cmdType == 4) {
                        // 群/会议通知消息
                        if (this.webrtcCallback != null && this.webrtcCallback[4] != null) {
                            this.webrtcCallback[4](json);
                        }
                    }
                    else if (json.cmdType >= 1) {
                        // 群/会议通知消息
                        if (this.webrtcCallback != null && this.webrtcCallback[2] != null) {
                            this.webrtcCallback[2](json);
                        }
                    }
                    else {
                        // 个人通知消息
                        if (this.webrtcCallback != null && this.webrtcCallback[0] != null) {
                            this.webrtcCallback[0](json);
                        }
                    }
                    break;
                case 'room':
                case 'signalOffer':
                case 'signalAnswer':
                case 'iceOffer':
                case 'close':
                case 'videoRatio':
                    console.log(json)
                    if (json.cmdType != null && json.cmdType == 0) {
                        if (this.webrtcCallback != null && this.webrtcCallback[3] != null) {
                            this.webrtcCallback[3](json);
                        }
                    } else {
                        if (this.webrtcCallback != null && this.webrtcCallback[2] != null) {
                            this.webrtcCallback[2](json);
                        }
                    }
                    break;
                case 'offline':

                    this.setUserOffline(json.sourceName);

                    if(this.webrtcCallback!=null && this.webrtcCallback[2]!=null) {
                        this.webrtcCallback[2](json);
                    }
                    if(this.webrtcCallback!=null && this.webrtcCallback[3]!=null) {
                        this.webrtcCallback[3](json);
                    }
                    if(this.webrtcCallback!=null && this.webrtcCallback[0]!=null) {
                        this.webrtcCallback[0](json);
                    }
                    break;
                default:
                    if ((!isEmpty(json.toUser) && json.toUser.indexOf('2-') >= 0)) {
                        if (this.webrtcCallback != null && this.webrtcCallback[2] != null) {
                            this.webrtcCallback[2](json);
                        }
                    } else if (this.webrtcCallback != null && this.webrtcCallback[0] != null) {
                        this.webrtcCallback[0](json);
                    }
                    break;
            }
        };

        this.ws.onerror = (e) => {
            console.log('================close==================')
            console.log('websocket 错误: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
            console.log(e);
            if(this.isReconnect)
                this.reconnect2();
        };

        this.ws.onclose = (e) => {
            console.log('================close==================')
            console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
            console.log(e)
            this.closeWs();
            if(this.isReconnect)
                this.reconnect2();
        };

    },
    reconnect2() {
        console.log('============reconnect2=============')
        if (this.lockReconnect) return;
        this.lockReconnect = true;
        clearTimeout(this.timer)
        if (this.data.limit < 12) {
            this.timer = setTimeout(() => {
                this.init();
                this.lockReconnect = false;
            }, 5000);
            this.setData(this.data.limit + 1);
        }
    },

    setData: function (limit) {
        this.data.limit = limit;
    },

    reconnect(userInfo, store) {
        console.log('============reconnect=============')
        console.log(this.ws)

        if (this.ws == null || this.ws.OPEN != 1) {
            this.connect(userInfo, store);
        }
        else {
            this.heartCheck.reset();
        }
    },

    closeWs() {
        this.ws = null;
    },

    // 添加会议在线用户
    addOnlineUser(item) {
        console.log('============setOnlineUsers=============')

        console.log(item)
        console.log(this.onlineUsers)
        if(item==null || item.id==null) return;
        let arr = this.onlineUsers.filter(r=> r.id!=null && r.id==item.id);
        if(arr.length<=0) {
            this.onlineUsers.push(item);
        }

        item.img = 'static/images/father.jpg';
        this.store.dispatch('addChatList', {type:0, user: item});

        this.store.commit("onlineList", this.onlineUsers);
    },

    // 检查离线用户
    getUserOffline(json) {
        // console.log('============getUserOffline===============')
        // console.log(json)
        if(json.userIds!=null) {
            let arr = json.userIds.split(',');
            arr.forEach(r=> {
                this.setUserOffline(getChatId(0, r));
            })
        }
    },

    // 设置用户离线状态
    setUserOffline(chatId) {
        this.store.dispatch('setUserOffline', {isOnline: false, chatId: chatId});
    },

    // 获取在线用户列表
    getOnlineUsers() {
        const sendJson = JSON.stringify({
            type: "getOnline"
        });
        this.ws.send(sendJson);
    },
    // 设置用户列表
    setOnlineUsers(json) {
        console.log('==========setOnlineUsers===============')

        console.log(json)

        if(json==null || json.userList==null) return;
        this.onlineUsers = json.userList;
        this.store.commit("onlineList", this.onlineUsers);

        this.onlineUsers.forEach(r=> {
            this.store.dispatch('addChatList', {type: 0, user: r});
        });
    },
    sendResponse(json) {
        console.log(json);
        this.store.dispatch('sendResponse', json);
    },
    receiveMsg(json) {
        console.log(json);
        this.store.dispatch('receiveMsg', json.message);
    },
    sendMsg(msg) {
        const sendJson = JSON.stringify({
            type: "send",
            toUser: msg.toUser,
            message: msg,
        });
        this.sendMessage(sendJson);
    },
    sendMessage(sendJson) {
        console.log('==============sendMessage==================')
        if(this.ws==null || this.ws.OPEN!=1) {
            this.reconnectCallback = {callback: this.sendCallback, msg: sendJson};
            this.reconnect2();
        }
        else {
            this.ws.send(sendJson);
        }
    },
    sendCallback() {
        let sendJson = this.reconnectCallback.msg;
        this.ws.send(sendJson);
        this.reconnectCallback = null;
    }

}
