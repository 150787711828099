//获取当前时间
import {TestEmojis} from "@/utils/TestEmojis";
import {getChatId, getMemberIds, getMsgIsShow, getNickName, isEmpty} from "@/utils/StringUtils";

function parseUserState(key) {
    return localStorage.getItem(key) !=null? localStorage.getItem(key) : '';
}

export default {
    state: {
        // 输入的搜索值
        searchText: '',
        // 当前登录用户
        user: {
            name: 'ratel',
            img: 'static/images/UserAvatar.jpg'
        },
        // 对话好友列表
        chatlist: isEmpty(parseUserState('chatlist'))?[]:JSON.parse(parseUserState('chatlist')),

        // 会议在线用户
        meetingUserList: [],

        // 好友列表
        friendlist: [],
        //emoji表情
        emojis: new TestEmojis(),
        // 得知当前选择的是哪个对话
        selectId: 0,
        // 得知当前选择的是哪个好友
        selectFriendId: 0,
        // 通知消息
        isNotify: false,
        notifyNum: 0,
  },
  getters: {
      getChatlist: function (state) {
          let info = state.chatlist;

          if(isEmpty(info)) {
              return [];
          }
          else if(info instanceof Object) {
              return info;
          }
          else {
              state.chatlist = JSON.parse(info);
              return state.chatlist;
          }
      },
      // 筛选出含有搜索值的聊天列表(展示会议)
      searchedChatlist (state) {

          let compare = function(a, b) {
              //isOnline 都为true
              if(a.lastTime==null) a.lastTime = 0;
              if(b.lastTime==null) b.lastTime = 0;
              if(a.talkType!=0) {
                  if(a.messages.length<=0)
                      a.lastTime = 0;
                  a.isOnline = true;
              }
              if(b.talkType!=0) {
                  if(b.messages.length<=0)
                      b.lastTime = 0;
                  b.isOnline = true;
              }

              if(a.isOnline && b.isOnline){
                  return a.lastTime > b.lastTime ? -1 : 1;
              }
              //isOnline 都为false
              else if(!a.isOnline && !b.isOnline){
                  return a.lastTime > b.lastTime ? -1 : 1;
              }
              else{
                  return a.isOnline > b.isOnline ? -1 : 1;
              }
          }

          let sessions = state.chatlist.filter(sessions => sessions.user!=null && sessions.user.nickName!=null
              && (sessions.talkType==0 || (
                      (sessions.talkType>0 && sessions.talkType<4)
                      && (
                          (sessions.user.members!=null && sessions.user.members.includes(','+state.user.id+','))
                          || sessions.user.anchorId==state.user.id
                      )
                  )
              )
              && sessions.user.nickName.includes(state.searchText));

          return sessions.sort(compare)
      },
      // 通过当前选择是哪个对话匹配相应的对话
      selectedChat (state) {
          let session = state.chatlist.find(session => session.id == state.selectId);

          return session
      },
      /**
       * 同步获取数据
       *
       * @param state
       * @returns {Promise<*[]>}
       */
      async selectByTargetId (state) {
          let session = await window.dbHelper.syncFindByTargetId(state.user.chatId, state.selectId);
          console.log(session)

          return session
      },
      // 通过当前选择是哪个好友匹配相应的好友
      selectedFriend (state) {
          let friend = state.friendlist.find(friend => friend.id == state.selectFriendId);
          console.log(friend)
          return friend
      },
      messages (state) {
          console.log('==============messages================')
          console.log(state.selectId)
          let session = state.chatlist.find(session => session.id == state.selectId);
          console.log('==============messages================')
          return session.messages
      },
      hasNotify(state) {
          return state.isNotify;
      },
      getNotifyNum(state) {
          return state.notifyNum;
      },
      getToken(state) {
          return state.token;
      },

      /**
       * 获取会议列表
       * @param state
       */
      searchedMeetinglist (state) {
          let compare = function(a, b) {
              //isOnline 都为true
              if(a.lastTime==null) a.lastTime = 0;
              if(b.lastTime==null) b.lastTime = 0;

              return a.lastTime > b.lastTime ? -1 : 1;
          }

          let sessions = state.chatlist.filter(sessions =>
              sessions.user!=null && sessions.user.nickName!=null
              && sessions.talkType==4
              // && sessions.user.targetId==state.user.id
              && (sessions.user.members.includes(','+state.user.id+',') || sessions.user.anchorId==state.user.id)
              && sessions.user.nickName.includes(state.searchText)
          );

          return sessions.sort(compare)
      },

      // 筛选出含有搜索值的好友列表
      searchedFriendlist (state) {
          let friends = state.friendlist.filter(friends => (friends.talkType==0 && friends.remarks.includes(state.searchText)));
          return friends
      },

      // 获取在线用户
      getOnlineUser(state) {
          let options = [];

          console.log('===========getOnlineUser================')
          console.log(state.chatlist)

          state.chatlist.forEach(r=> {
              if(r.isOnline && r.talkType==0)
                  options.push({label: getNickName(r.user), value: r.id});
          });

          return options;
      },

  },
  mutations: {
      chatlist(state, val) {
          state.chatlist = val;
          if(val == null || val=='')
              localStorage.removeItem("chatlist");
          else
              localStorage.setItem("chatlist", JSON.stringify(val));
      },
      meetingUserList(state, val) {
          state.meetingUserList = val;
          if(val == null || val=='')
              sessionStorage.removeItem("meetingUserList");
          else
              sessionStorage.setItem("meetingUserList", JSON.stringify(val));
      },
      // 从localStorage 中获取数据
      initData (state) {

          console.log('============initData============')
          let data = localStorage.getItem('chatlist');

          console.log(data)

          if(isEmpty(data)) {
              state.chatlist = [];
          }
          else if (data instanceof Object) {
              state.chatlist = data;
          }
          else {
              state.chatlist = JSON.parse(data);
          }
      },
      addChatList(state, item) {

            if (item == null || item.user == null) return;
            let chatId = getChatId(item.type, item.user.id);

          let arr = state.chatlist.filter(r=>r.id===chatId);
          if(arr.length<=0) {
              state.chatlist.push({
                  id: chatId,
                  userId: item.user.id,
                  talkType: item.type,
                  user: item.user,
                  notifyNum:0,
                  messages: [], // 消息列表
                  index: state.chatlist.length,
                  content: '',
                  isOnline: true,
                  lastTime: Date.now(),
                  lastLoginTime: Date.now(),
              })
          }
          else {
              arr[0].isOnline = true;
              arr[0].lastLoginTime = Date.now();
          }

          localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
      },
      setUserOffline(state, item) {
          if(item==null || item.chatId==null) return;

          let arr = state.chatlist.filter(r=>r.id===item.chatId);
          if(arr.length>0) {
              arr[0].isOnline = false;
          }

          localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
      },
      removeChatList(state, item) {
          let chatId = getChatId(item.type, item.userId);

          const index = state.chatlist.findIndex((i) => i.id == chatId);
          if (index > -1) {
              state.chatlist.splice(index, 1);

              localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
          }

      },
      addRoomMember(state, item) {
          console.log('============addRoomMember===============')
          let roomInfo = state.meetingUserList.find(r => r.roomId == item.roomId);
          if(roomInfo!=null) {
              if(roomInfo.members==null) roomInfo.members = [];
              if(!roomInfo.members.includes(item.userId))
                  roomInfo.members.push(item.userId);
              console.log(roomInfo.members)
          }
      },
      removeRoomMember(state, item) {
          console.log('============removeRoomMember===============')
          console.log(item)
          let roomInfo = state.meetingUserList.find(r => r.roomId == item.roomId);
          if(roomInfo!=null) {
              if(roomInfo.members==null) roomInfo.members = [];

              let index = roomInfo.members.findIndex(r=> r===item.userId)
              if (index > -1) {
                  let node = roomInfo.members.splice(index, 1);
                  console.log(node)
              }
              console.log(state.meetingUserList)
          }
      },
      setUser(state, userInfo) {
          let chatId = getChatId(0, userInfo.id);
          state.user = userInfo;
          state.user.chatId = chatId;
      },
      // 获取搜索值
      search (state, value) {
          state.searchText = value
      },
      // 得知用户当前选择的是哪个对话。便于匹配对应的对话框
      selectSession (state, value) {
          console.log(state.selectId+'==state.selectId=='+value)

          let result = state.chatlist.find(session => session.id == value);
          if(result!=null && result.notifyNum!=null && result.notifyNum>0) {
              result.notifyNum = 0;

              // 保存消息
              localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
          }
          state.selectId = value
      },
      /**
       * 保存临时内容
       *
       * @param state
       * @param content
       */
      saveContent(state, content) {
          let result = state.chatlist.find(session => session.id == state.selectId);
          if(result!=null)
              result.content = content;
      },
      // 得知用户当前选择的是哪个好友。
      selectFriend (state, value) {
          state.selectFriendId = value
      },
      // 文件下载回调：失败、完成、下载中
      downloadError(state, item) {
          let result = state.chatlist.find(session => session.id == item.targetId);

          let message = result.messages.find(msg=> msg.msgId==item.msgId);
          if(message.ext==null)
              message.ext = {downTime: Date.now()};
          else
              message.ext.downTime = Date.now();

          window.dbHelper.updateDownStatus(state.user.id, {msgId: item.msgId, downStatus: 2, downTime: Date.now()});

          message.downStatus = 2;// 上传失败
      },
      downloadProgressing(state, item) {
          console.log(item)

          let result = state.chatlist.find(session => session.id == item.targetId);
          console.log(result)

          let message = result.messages.find(msg=> msg.msgId==item.msgId);

          if(message.ext==null)
              message.ext = {downTotal: item.total, downReceived: item.loaded}
          else {
              message.ext.downReceived = item.loaded;
              message.ext.downTotal = item.total;
          }
          message.downStatus = 0;// 上传中
          message.ext.downTime = Date.now();

          let complete = (100-(item.loaded / item.total * 100 | 0)) + '%'
          message.ext.downComplete = complete;
      },
      downloadComplete(state, fileInfo) {
          let result = state.chatlist.find(session => session.id == fileInfo.targetId);
          let message = result.messages.find(msg=> msg.msgId==fileInfo.msgId);

          message.downStatus = 1;// 完成
          message.ext.downTime = Date.now();

          if(message.ext==null)
              message.ext = {srcFile: fileInfo.fileName, srcFileUrl: fileInfo.filePath}
          else {
              message.ext.srcFile = fileInfo.fileName;
              message.ext.srcFileUrl = fileInfo.filePath;
          }

          // 更新本地数据库文件下载状态
          window.dbHelper.updateDownStatus(state.user.id, {msgId: fileInfo.msgId, downStatus: 1, downTime: Date.now(), srcFile: fileInfo.fileName, srcFileUrl: fileInfo.filePath});

          // 保存消息
          localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
      },

      // 文件上传回调：失败、成功、上传中
      uploadError(state, item) {
          let result = state.chatlist.find(session => session.id == item.targetId);

          let message = result.messages.find(msg=> msg.msgId===item.msgId);

          if(message.ext==null)
              message.ext = {downTime: Date.now()};
          else
              message.ext.downTime = Date.now();

          message.sendStatus = 2;// 上传失败

          // 更新本地数据库文件下载状态
          window.dbHelper.updateUploadStatus(state.user.id, {msgId: item.msgId, sendStatus: 2, lastTime: Date.now() });
      },
      uploadProgressing(state, item) {
          let result = state.chatlist.find(session => session.id == item.targetId);

          let message = result.messages.find(msg=> msg.msgId===item.msgId);
          if(message.ext==null)
              message.ext = {totalBytes: item.total, received: item.loaded}
          else {
              message.ext.received = item.loaded;
              message.ext.totalBytes = item.total;
          }
          message.sendStatus = 0;// 上传中
          message.ext.lastTime = Date.now();

          let complete = (100-(item.loaded / item.total * 100 | 0)) + '%'
          message.ext.complete = complete;
      },
      uploadComplete(state, fileInfo) {

          let result = state.chatlist.find(session => session.id == fileInfo.targetId);
          let message = result.messages.find(msg=> msg.msgId===fileInfo.msgId);

          message.sendStatus = 1;// 完成
          message.ext.lastTime = Date.now();
          message.sendTime =  fileInfo.fields[2];
          let fileUrl =  fileInfo.fields[4];
          if(!isEmpty(fileUrl)) {
              fileUrl = fileUrl.replace("http://219.142.195.43", "https://kj.isuger.com.cn");
          }
          if(message.ext==null)
              message.ext = {fileId: fileInfo.fields[0], fileUrl: fileUrl}
          else {
              message.ext.fileId = fileInfo.fields[0];
              message.ext.fileUrl =fileUrl;
          }

          // 更新本地数据库文件下载状态
          window.dbHelper.updateUploadStatus(state.user.id, {msgId: fileInfo.msgId, sendStatus: 1, lastTime: Date.now(), fileUrl: fileUrl, fileId: fileInfo.fields[0]});

          console.log(message)
          // 文件上传完毕 发送消息
          fileInfo.service.sendMsg(message);

          // 保存消息
          localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
      },

      // 发送信息
      sendMessage (state, msg) {
        let service = msg.service;
        let result = state.chatlist.find(session => session.id == state.selectId);
        let content = msg.content.trim().replaceAll('<div><br></div>','');

        let item = {
            msgId: msg.msgId,
            fromUser: state.user.chatId,
            toUser: state.selectId,
            content: encodeURIComponent(content),
            ext: msg.ext,
            msgType: msg.msgType,
            sendStatus: msg.sendStatus!=null?msg.sendStatus: 1,
            date: new Date(),
            sendTime: Date.now(),
            self: true,
            delete: 0,// 删除标记
        };

        if(msg.msgType=='recall') {
            // 删除本地记录
            if(msg.ext==null || isEmpty(msg.ext.msgId)) return;

            let message = result.messages.find(item=> item.msgId===msg.ext.msgId);
            if(message!=null) {
                message.delete = 1;
                if(message.ext==null)
                    message.ext = {deleteTime: Date.now()};
                else
                    message.ext.deleteTime = Date.now();
            }
        }

        if(msg.sendStatus!=null && msg.sendStatus==0) {
            // 发送中---应用于发送文件
        }
        else {
            service.sendMsg(item);
        }

        // 同步到本地数据库
        window.dbHelper.saveChatMessage(state.user.id, item).then(result=> {
        }, error=> {
            console.log('error',error);
        });

        result.messages.push(item);

        localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
    },
    /**
     * 添加不存在的记录
     *
     * @param state
     * @param item
     */
    addMessage(state, item) {
        // console.log('============addMessage===============')
        if(item==null) return;
        let targetId = state.user.chatId==item.toUser?item.fromUser:item.toUser;

        if(item==null || item.msgId==null || item.fromUser==null || item.toUser==null) return;
        let result = state.chatlist.find(session => session.id == targetId);
        if(result==null) return;
        let message = result.messages.find(msg=> msg.msgId===item.msgId);
        if(message==null) {
            result.messages.push(item);
        }
        else{
            message.self = item.self;
            message.msgType = item.msgType;
            message.isShow = item.isShow!=null?item.isShow: getMsgIsShow(item.msgType);
        }
    },
    /**
     * 添加用户
     *
     * @param state
     * @param item
     */
    addMeetingInfo(state, item) {
        let chatId = getChatId(item.talkType, item.id);
        let result = state.friendlist.find(userInfo => userInfo.id == chatId);

        if(result==null) {
            state.friendlist.push({
                id: chatId,
                userId: item.id,
                nickName: item.groupName,
                name: item.groupName,
                phone: '',
                avatar: item.avatar,
                addTime: item.createTime,
                status: 0,
                targetId: item.userId,
                talkType: item.talkType,
                remarks: '',
                memberIds: item.memberIds,
                lastTime: Date.now(),
            });
        }

    },
    /**
     * 添加到会话列表
     *
     * @param state
     * @param item
     */
    addGroupList(state, item) {
        let chatId = getChatId(item.talkType, item.id);

        let groupInfo = {
            "id" : item.id,
            "chatId": chatId,
            "anchorId": item.userId,
            "nickName" : item.groupName,
            "phone" : '',
            "passWord" : null,
            "memberId" : 0,
            "members": isEmpty(item.memberIds)?'':','+item.memberIds+',',// 成员列表
            "avatar" : item.avatar,
            "token" : "",
            "name" : item.groupName,
            "modifyTime" : item.modifyTime,
            "deleted" : 0,
            "isRoom": false,
        }

        let arr = state.chatlist.find(r=>r.id==chatId);
        if(arr==null) {
          state.chatlist.push({
              id: chatId,
              userId: item.id,
              talkType: item.talkType,
              user: groupInfo,
              notifyNum:0,
              messages: [], // 消息列表
              index: state.chatlist.length,
              content: '',
              isOnline: true,
              lastTime: Date.now(),
              lastLoginTime: Date.now(),
          })
        }
        else {
            arr.talkType = item.talkType;
            arr.user = groupInfo;
        }

        localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
    },
    /**
     * 更新群组成员
     *
     * @param state
     * @param item
     */
    updateGroupMember(state, item) {
        let groupInfo = state.chatlist.find(r=>r.id===item.chatId);
        if(groupInfo!=null && groupInfo.user!=null) {
            let memberArr = getMemberIds(groupInfo.user.members);
            let found = false;
            if(memberArr.indexOf(item.userId)>=0)
                found = true;
            if(!found) {
                memberArr.push(item.userId);
                groupInfo.user.members = ','+memberArr.join(',')+',';

                localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
            }
        }
    },
    /**
     * 添加用户
     *
     * @param state
     * @param item
     */
    addChatContact(state, item) {
      let chatId = getChatId(0, item.id);
      let result = state.friendlist.find(userInfo => userInfo.id == chatId);

      if(result==null) {
          state.friendlist.push({
              id: chatId,
              userId: item.id,
              nickName: item.nickName,
              name: item.name,
              phone: item.phone,
              avatar: item.avatar,
              addTime: item.modifyTime,
              status: 0,
              targetId: 0,
              talkType: 0,
              remarks: '',
              memberIds: '',
              lastTime: Date.now(),
          });
      }
    },
      /**
       * 获取用户信息
       *
       * @param state
       * @param item
       * @returns {*}
       */
    getChatContact(state, chatId) {

        let userInfo = state.friendlist.find(user => user.id == chatId);
        console.log(userInfo)
        return userInfo;
    },

    sendResponse(state, json) {
        let result = state.chatlist.find(session => session.id == json.toUser);
        if(result==null) return;
        let message = result.messages.find(msg=> msg.msgId===json.msgId);
        if(message!=null) {
            message.sendTime = json.sendTime;
            result.lastTime = json.sendTime;

            // 更新本地数据库状态
            window.dbHelper.updateSendStatus(state.user.id, json);
        }

        localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
    },
    receiveMsg(state, msg) {
        let targetChat = msg.fromUser;

        let talkType = 0;
        try {
            talkType = parseInt(msg.toUser.substring(0, 1));
        }
        catch (e) {
            console.log(e);
            talkType = 0;
        }

        if (talkType>0) {
            targetChat = msg.toUser;
        }
        let result = state.chatlist.find(session => session.id === targetChat);

        msg.self = msg.fromUser === state.user.id;

        if(targetChat!=state.selectId) {
            result.notifyNum = result.notifyNum!=null?result.notifyNum+1:1;
        }

        // 服务器时间
        result.lastTime = msg.sendTime!=null && msg.sendTime>0?msg.sendTime:Date.now();

        state.isNotify = !msg.self;
        state.notifyNum = state.notifyNum + 1;

        console.log(result.notifyNum + '=========receiveMsg===========' + state.isNotify)
        console.log(msg);

        if(msg.msgType=='recall') {
            // 删除本地记录
            if(msg.ext!=null && !isEmpty(msg.ext.msgId)) {
                let message = result.messages.find(item=> item.msgId===msg.ext.msgId);
                if(message!=null) {
                    message.delete = 1;
                    if(message.ext==null)
                        message.ext = {deleteTime: Date.now()};
                    else
                        message.ext.deleteTime = Date.now();
                }
            }
        }

        // 同步到本地数据库
        window.dbHelper.saveChatMessage(state.user.id, msg).then(result=> {
            console.log(result);
        }, error=> {
            console.log(error);
        });

        result.messages.push(msg);

        localStorage.setItem("chatlist", JSON.stringify(state.chatlist));
    },
    setNotifyNum(state, num) {
        state.notifyNum = num;
    },

    // 选择好友后，点击发送信息。判断在聊天列表中是否有该好友，有的话跳到该好友对话。没有的话
    // 添加该好友的对话 并置顶
    send(state) {
        console.log('=================send==============')
        let result = state.friendlist.find(friend => friend.id == state.selectFriendId)
        let msg = state.chatlist.find(msg => msg.user.name == result.remark)
        if (!msg) {
            state.selectId = 1
            for (let i = 0; i < state.chatlist.length; i++) {
                state.chatlist[i].id++;
                state.chatlist[i].index++;
            }
            state.chatlist.unshift({
                id: 1,
                user: {
                    name: result.remark,
                    img: result.img
                },
                messages: [
                    {
                        content: '已经置顶聊天，可以给我发信息啦！',
                        date: new Date()
                    }
                ],
                index: 1
            })
        } else {
            state.selectId = msg.index
            // router.push({ path: '/chat'})
        }
    },

  },
  actions: {
      search: ({ commit }, value) => {
          setTimeout(() => {
              commit('search', value)
          }, 100)
      },
      selectSession: ({ commit }, value) => commit('selectSession', value),
      selectFriend: ({ commit }, value) => commit('selectFriend', value),
      sendMessage: ({ commit }, msg) => commit('sendMessage', msg),
      receiveMsg: ({ commit }, msg) => commit('receiveMsg', msg),
      sendResponse: ({ commit }, msg) => commit('sendResponse', msg),
      saveContent: ({ commit }, item) => commit('saveContent', item),

      // 同步远程数据
      addMessage: ({ commit }, msg) => commit('addMessage', msg),
      addChatContact: ({ commit }, msg) => commit('addChatContact', msg),
      addMeetingInfo: ({ commit }, msg) => commit('addMeetingInfo', msg),
      getChatContact: ({ commit }, msg) => commit('getChatContact', msg),


      // 下载文件
      downloadProgressing: ({ commit }, msg) => commit('downloadProgressing', msg),
      downloadComplete: ({ commit }, msg) => commit('downloadComplete', msg),
      downloadError: ({ commit }, msg) => commit('downloadError', msg),

      // 上传文件
      uploadProgressing: ({ commit }, msg) => commit('uploadProgressing', msg),
      uploadComplete: ({ commit }, msg) => commit('uploadComplete', msg),
      uploadError: ({ commit }, msg) => commit('uploadError', msg),


      send: ({ commit }) => commit('send'),
      initData: ({ commit }) => commit('initData'),
      addChatList: ({ commit }, item) => commit('addChatList', item),
      addGroupList: ({ commit }, item) => commit('addGroupList', item),
      updateGroupMember: ({ commit }, item) => commit('updateGroupMember', item),
      saveGroupMember: ({ commit }, item) => commit('saveGroupMember', item),


      setUserOffline: ({ commit }, item) => commit('setUserOffline', item),

      removeChatList: ({ commit }, userId) => commit('removeChatList', userId),

      // 会议/群组方法
      addRoomMember: ({ commit }, item) => commit('addRoomMember', item),
      removeRoomMember: ({ commit }, item) => commit('removeRoomMember', item),

      setChatList(context,chatList){
          context.commit('chatList',chatList)
      },
      setUser: ({ commit }, userInfo) => commit('setUser', userInfo),
      setNotifyNum: ({ commit }, value) => commit('setNotifyNum', value),
  }
}

// // 获取当前时间
// import { TestEmojis } from '@/utils/TestEmojis'
// import { getChatId, getMemberIds, getMsgIsShow, getNickName, isEmpty } from '@/utils/StringUtils'

// function parseUserState (key) {
//   return localStorage.getItem(key) !== null ? localStorage.getItem(key) : ''
// }

// export default {
//   state: {
//     // 输入的搜索值
//     searchText: '',
//     // 当前登录用户
//     user: {
//       name: 'ratel',
//       img: 'static/images/UserAvatar.jpg'
//     },
//     // 对话好友列表
//     chatlist: isEmpty(parseUserState('chatlist')) ? [] : JSON.parse(parseUserState('chatlist')),

//     // 会议在线用户
//     meetingUserList: [],

//     // 好友列表
//     friendlist: [],
//     // emoji表情
//     emojis: new TestEmojis(),
//     // 得知当前选择的是哪个对话
//     selectId: 0,
//     // 得知当前选择的是哪个好友
//     selectFriendId: 0,
//     // 通知消息
//     isNotify: false,
//     notifyNum: 0
//   },
//   getters: {
//     getChatlist: function (state) {
//       const info = state.chatlist

//       if (isEmpty(info)) {
//         return []
//       } else if (info instanceof Object) {
//         return info
//       } else {
//         state.chatlist = JSON.parse(info)
//         return state.chatlist
//       }
//     },
//     // 筛选出含有搜索值的聊天列表(展示会议)
//     searchedChatlist (state) {
//       const compare = function (a, b) {
//         // isOnline 都为true
//         if (a.lastTime === null) a.lastTime = 0
//         if (b.lastTime === null) b.lastTime = 0
//         if (a.talkType !== 0) {
//           if (a.messages.length <= 0) a.lastTime = 0
//           a.isOnline = true
//         }
//         if (b.talkType !== 0) {
//           if (b.messages.length <= 0) b.lastTime = 0
//           b.isOnline = true
//         }

//         if (a.isOnline && b.isOnline) {
//           return a.lastTime > b.lastTime ? -1 : 1
//         } else if (!a.isOnline && !b.isOnline) { // isOnline 都为false
//           return a.lastTime > b.lastTime ? -1 : 1
//         } else {
//           return a.isOnline > b.isOnline ? -1 : 1
//         }
//       }

//       const sessions = state.chatlist.filter(
//         (sessions) =>
//           sessions.user !== null &&
//           sessions.user.nickName !== null &&
//           (sessions.talkType === 0 ||
//             (sessions.talkType > 0 &&
//               sessions.talkType < 4 &&
//               ((sessions.user.members !== null &&
//                 sessions.user.members.includes(',' + state.user.id + ',')) ||
//                 sessions.user.anchorId === state.user.id))) &&
//           sessions.user.nickName.includes(state.searchText)
//       )

//       return sessions.sort(compare)
//     },
//     // 通过当前选择是哪个对话匹配相应的对话
//     selectedChat (state) {
//       const session = state.chatlist.find((session) => session.id === state.selectId)

//       return session
//     },
//     /**
//      * 同步获取数据
//      *
//      * @param state
//      * @returns {Promise<*[]>}
//      */
//     async selectByTargetId (state) {
//       const session = await window.dbHelper.syncFindByTargetId(state.user.chatId, state.selectId)
//       console.log(session)

//       return session
//     },
//     // 通过当前选择是哪个好友匹配相应的好友
//     selectedFriend (state) {
//       const friend = state.friendlist.find((friend) => friend.id === state.selectFriendId)
//       console.log(friend)
//       return friend
//     },
//     messages (state) {
//       console.log('================messages==================')
//       console.log(state.selectId)
//       const session = state.chatlist.find((session) => session.id === state.selectId)
//       console.log('================messages==================')
//       return session.messages
//     },
//     hasNotify (state) {
//       return state.isNotify
//     },
//     getNotifyNum (state) {
//       return state.notifyNum
//     },
//     getToken (state) {
//       return state.token
//     },

//     /**
//      * 获取会议列表
//      * @param state
//      */
//     searchedMeetinglist (state) {
//       const compare = function (a, b) {
//         // isOnline 都为true
//         if (a.lastTime === null) a.lastTime = 0
//         if (b.lastTime === null) b.lastTime = 0

//         return a.lastTime > b.lastTime ? -1 : 1
//       }

//       const sessions = state.chatlist.filter(
//         (sessions) =>
//           sessions.user !== null &&
//           sessions.user.nickName !== null &&
//           sessions.talkType === 4 &&
//           // && sessions.user.targetId===state.user.id
//           (sessions.user.members.includes(',' + state.user.id + ',') ||
//             sessions.user.anchorId === state.user.id) &&
//           sessions.user.nickName.includes(state.searchText)
//       )

//       return sessions.sort(compare)
//     },

//     // 筛选出含有搜索值的好友列表
//     searchedFriendlist (state) {
//       const friends = state.friendlist.filter(
//         (friends) => friends.talkType === 0 && friends.remarks.includes(state.searchText)
//       )
//       return friends
//     },

//     // 获取在线用户
//     getOnlineUser (state) {
//       const options = []

//       console.log('============getOnlineUser==================')
//       console.log(state.chatlist)

//       state.chatlist.forEach((r) => {
//         if (r.isOnline && r.talkType === 0) options.push({ label: getNickName(r.user), value: r.id })
//       })

//       return options
//     }
//   },
//   mutations: {
//     chatlist (state, val) {
//       state.chatlist = val
//       if (val === null || val === '') localStorage.removeItem('chatlist')
//       else localStorage.setItem('chatlist', JSON.stringify(val))
//     },
//     meetingUserList (state, val) {
//       state.meetingUserList = val
//       if (val === null || val === '') sessionStorage.removeItem('meetingUserList')
//       else sessionStorage.setItem('meetingUserList', JSON.stringify(val))
//     },
//     // 从localStorage 中获取数据
//     initData (state) {
//       console.log('==============initData==============')
//       const data = localStorage.getItem('chatlist')

//       console.log(data)

//       if (isEmpty(data)) {
//         state.chatlist = []
//       } else if (data instanceof Object) {
//         state.chatlist = data
//       } else {
//         state.chatlist = JSON.parse(data)
//       }
//     },
//     addChatList (state, item) {
//       if (item === null || item.user === null) return
//       const chatId = getChatId(item.type, item.user.id)

//       const arr = state.chatlist.filter((r) => r.id === chatId)
//       if (arr.length <= 0) {
//         state.chatlist.push({
//           id: chatId,
//           userId: item.user.id,
//           talkType: item.type,
//           user: item.user,
//           notifyNum: 0,
//           messages: [], // 消息列表
//           index: state.chatlist.length,
//           content: '',
//           isOnline: true,
//           lastTime: Date.now(),
//           lastLoginTime: Date.now()
//         })
//       } else {
//         arr[0].isOnline = true
//         arr[0].lastLoginTime = Date.now()
//       }

//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },
//     setUserOffline (state, item) {
//       if (item === null || item.chatId === null) return

//       const arr = state.chatlist.filter((r) => r.id === item.chatId)
//       if (arr.length > 0) {
//         arr[0].isOnline = false
//       }

//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },
//     removeChatList (state, item) {
//       const chatId = getChatId(item.type, item.userId)

//       const index = state.chatlist.findIndex((i) => i.id === chatId)
//       if (index > -1) {
//         state.chatlist.splice(index, 1)

//         localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//       }
//     },
//     addRoomMember (state, item) {
//       console.log('==============addRoomMember=================')
//       const roomInfo = state.meetingUserList.find((r) => r.roomId === item.roomId)
//       if (roomInfo !== null) {
//         if (roomInfo.members === null) roomInfo.members = []
//         if (!roomInfo.members.includes(item.userId)) roomInfo.members.push(item.userId)
//         console.log(roomInfo.members)
//       }
//     },
//     removeRoomMember (state, item) {
//       console.log('==============removeRoomMember=================')
//       console.log(item)
//       const roomInfo = state.meetingUserList.find((r) => r.roomId === item.roomId)
//       if (roomInfo !== null) {
//         if (roomInfo.members === null) roomInfo.members = []

//         const index = roomInfo.members.findIndex((r) => r === item.userId)
//         if (index > -1) {
//           const node = roomInfo.members.splice(index, 1)
//           console.log(node)
//         }
//         console.log(state.meetingUserList)
//       }
//     },
//     setUser (state, userInfo) {
//       const chatId = getChatId(0, userInfo.id)
//       state.user = userInfo
//       state.user.chatId = chatId
//     },
//     // 获取搜索值
//     search (state, value) {
//       state.searchText = value
//     },
//     // 得知用户当前选择的是哪个对话。便于匹配对应的对话框
//     selectSession (state, value) {
//       console.log(state.selectId + '===state.selectId===' + value)

//       // const result = state.chatlist.find((session) => session.id === value)
//       // if (result !== null && result.notifyNum !== null && result.notifyNum > 0) {
//       //   result.notifyNum = 0

//       //   // 保存消息
//       //   localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//       // }
//         localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//         state.selectId = value
//     },
//     /**
//      * 保存临时内容
//      *
//      * @param state
//      * @param content
//      */
//     saveContent (state, content) {
//       const result = state.chatlist.find((session) => session.id === state.selectId)
//       if (result !== null) result.content = content
//     },
//     // 得知用户当前选择的是哪个好友。
//     selectFriend (state, value) {
//       state.selectFriendId = value
//     },
//     // 文件下载回调：失败、完成、下载中
//     downloadError (state, item) {
//       const result = state.chatlist.find((session) => session.id === item.targetId)

//       const message = result.messages.find((msg) => msg.msgId === item.msgId)
//       if (message.ext === null) message.ext = { downTime: Date.now() }
//       else message.ext.downTime = Date.now()

//       window.dbHelper.updateDownStatus(state.user.id, {
//         msgId: item.msgId,
//         downStatus: 2,
//         downTime: Date.now()
//       })

//       message.downStatus = 2 // 上传失败
//     },
//     downloadProgressing (state, item) {
//       console.log(item)

//       const result = state.chatlist.find((session) => session.id === item.targetId)
//       console.log(result)

//       const message = result.messages.find((msg) => msg.msgId === item.msgId)

//       if (message.ext === null) message.ext = { downTotal: item.total, downReceived: item.loaded }
//       else {
//         message.ext.downReceived = item.loaded
//         message.ext.downTotal = item.total
//       }
//       message.downStatus = 0 // 上传中
//       message.ext.downTime = Date.now()

//       const complete = 100 - (((item.loaded / item.total) * 100) | 0) + '%'
//       message.ext.downComplete = complete
//     },
//     downloadComplete (state, fileInfo) {
//       const result = state.chatlist.find((session) => session.id === fileInfo.targetId)
//       const message = result.messages.find((msg) => msg.msgId === fileInfo.msgId)

//       message.downStatus = 1 // 完成
//       message.ext.downTime = Date.now()

//       if (message.ext === null) { message.ext = { srcFile: fileInfo.fileName, srcFileUrl: fileInfo.filePath } } else {
//         message.ext.srcFile = fileInfo.fileName
//         message.ext.srcFileUrl = fileInfo.filePath
//       }

//       // 更新本地数据库文件下载状态
//       window.dbHelper.updateDownStatus(state.user.id, {
//         msgId: fileInfo.msgId,
//         downStatus: 1,
//         downTime: Date.now(),
//         srcFile: fileInfo.fileName,
//         srcFileUrl: fileInfo.filePath
//       })

//       // 保存消息
//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },

//     // 文件上传回调：失败、成功、上传中
//     uploadError (state, item) {
//       const result = state.chatlist.find((session) => session.id === item.targetId)

//       const message = result.messages.find((msg) => msg.msgId === item.msgId)

//       if (message.ext === null) message.ext = { downTime: Date.now() }
//       else message.ext.downTime = Date.now()

//       message.sendStatus = 2 // 上传失败

//       // 更新本地数据库文件下载状态
//       window.dbHelper.updateUploadStatus(state.user.id, {
//         msgId: item.msgId,
//         sendStatus: 2,
//         lastTime: Date.now()
//       })
//     },
//     uploadProgressing (state, item) {
//       const result = state.chatlist.find((session) => session.id === item.targetId)

//       const message = result.messages.find((msg) => msg.msgId === item.msgId)
//       if (message.ext === null) message.ext = { totalBytes: item.total, received: item.loaded }
//       else {
//         message.ext.received = item.loaded
//         message.ext.totalBytes = item.total
//       }
//       message.sendStatus = 0 // 上传中
//       message.ext.lastTime = Date.now()

//       const complete = 100 - (((item.loaded / item.total) * 100) | 0) + '%'
//       message.ext.complete = complete
//     },
//     uploadComplete (state, fileInfo) {
//       const result = state.chatlist.find((session) => session.id === fileInfo.targetId)
//       const message = result.messages.find((msg) => msg.msgId === fileInfo.msgId)

//       message.sendStatus = 1 // 完成
//       message.ext.lastTime = Date.now()
//       message.sendTime = fileInfo.fields[2]
//       let fileUrl = fileInfo.fields[4]
//       if (!isEmpty(fileUrl)) {
//         fileUrl = fileUrl.replace('http://219.142.195.43', 'https://kj.isuger.com.cn')
//       }
//       if (message.ext === null) message.ext = { fileId: fileInfo.fields[0], fileUrl: fileUrl }
//       else {
//         message.ext.fileId = fileInfo.fields[0]
//         message.ext.fileUrl = fileUrl
//       }

//       // 更新本地数据库文件下载状态
//       window.dbHelper.updateUploadStatus(state.user.id, {
//         msgId: fileInfo.msgId,
//         sendStatus: 1,
//         lastTime: Date.now(),
//         fileUrl: fileUrl,
//         fileId: fileInfo.fields[0]
//       })

//       console.log(message)
//       // 文件上传完毕 发送消息
//       fileInfo.service.sendMsg(message)

//       // 保存消息
//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },

//     // 发送信息
//     sendMessage (state, msg) {
//       const service = msg.service
//       const result = state.chatlist.find((session) => session.id === state.selectId)
//       console.log(msg)
//       console.log(state.user)
//       const content = msg.content.trim().replaceAll('<div><br></div>', '')

//       const item = {
//         msgId: msg.msgId,
//         fromUser: state.user.chatId,
//         toUser: state.selectId,
//         content: encodeURIComponent(content),
//         ext: msg.ext,
//         msgType: msg.msgType,
//         sendStatus: msg.sendStatus !== null ? msg.sendStatus : 1,
//         date: new Date(),
//         sendTime: Date.now(),
//         self: true,
//         delete: 0 // 删除标记
//       }

//       if (msg.msgType === 'recall') {
//         // 删除本地记录
//         if (msg.ext === null || isEmpty(msg.ext.msgId)) return

//         const message = result.messages.find((item) => item.msgId === msg.ext.msgId)
//         if (message !== null) {
//           message.delete = 1
//           if (message.ext === null) message.ext = { deleteTime: Date.now() }
//           else message.ext.deleteTime = Date.now()
//         }
//       }

//       if (msg.sendStatus !== null && msg.sendStatus === 0) {
//         // 发送中---应用于发送文件
//       } else {
//         service.sendMsg(item)
//       }

//       // 同步到本地数据库
//       window.dbHelper.saveChatMessage(state.user.id, item).then(
//         (result) => {
//           console.log(result)
//         },
//         (error) => {
//           console.log(error)
//         }
//       )

//       result.messages.push(item)

//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },
//     /**
//      * 添加不存在的记录
//      *
//      * @param state
//      * @param item
//      */
//     addMessage (state, item) {
//       // console.log('==============addMessage=================')
//       if (item == null) return
//       const targetId = state.user.chatId == item.toUser ? item.fromUser : item.toUser

//       if (item == null || item.msgId == null || item.fromUser == null || item.toUser == null) return
//       const result = state.chatlist.find((session) => session.id == targetId)
//       if (result == null) return
//       const message = result.messages.find((msg) => msg.msgId == item.msgId)
//       if (message == null) {
//         result.messages.push(item)
//       } else {
//         message.self = item.self
//         message.msgType = item.msgType
//         message.isShow = item.isShow !== null ? item.isShow : getMsgIsShow(item.msgType)
//       }
//     },
//     /**
//      * 添加用户
//      *
//      * @param state
//      * @param item
//      */
//     addMeetingInfo (state, item) {
//       const chatId = getChatId(item.talkType, item.id)
//       const result = state.friendlist.find((userInfo) => userInfo.id === chatId)

//       if (result == null) {
//         state.friendlist.push({
//           id: chatId,
//           userId: item.id,
//           nickName: item.groupName,
//           name: item.groupName,
//           phone: '',
//           avatar: item.avatar,
//           addTime: item.createTime,
//           status: 0,
//           targetId: item.userId,
//           talkType: item.talkType,
//           remarks: '',
//           memberIds: item.memberIds,
//           lastTime: Date.now()
//         })
//       }
//     },
//     /**
//      * 添加到会话列表
//      *
//      * @param state
//      * @param item
//      */
//     addGroupList (state, item) {
//       const chatId = getChatId(item.talkType, item.id)

//       const groupInfo = {
//         id: item.id,
//         chatId: chatId,
//         anchorId: item.userId,
//         nickName: item.groupName,
//         phone: '',
//         passWord: null,
//         memberId: 0,
//         members: isEmpty(item.memberIds) ? '' : ',' + item.memberIds + ',', // 成员列表
//         avatar: item.avatar,
//         token: '',
//         name: item.groupName,
//         modifyTime: item.modifyTime,
//         deleted: 0,
//         isRoom: false
//       }

//       const arr = state.chatlist.find((r) => r.id == chatId)
//       if (arr === null) {
//         state.chatlist.push({
//           id: chatId,
//           userId: item.id,
//           talkType: item.talkType,
//           user: groupInfo,
//           notifyNum: 0,
//           messages: [], // 消息列表
//           index: state.chatlist.length,
//           content: '',
//           isOnline: true,
//           lastTime: Date.now(),
//           lastLoginTime: Date.now()
//         })
//       } else {
//         arr.talkType = item.talkType
//         arr.user = groupInfo
//       }

//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },
//     /**
//      * 更新群组成员
//      *
//      * @param state
//      * @param item
//      */
//     updateGroupMember (state, item) {
//       const groupInfo = state.chatlist.find((r) => r.id == item.chatId)
//       if (groupInfo !== null && groupInfo.user !== null) {
//         const memberArr = getMemberIds(groupInfo.user.members)
//         let found = false
//         if (memberArr.indexOf(item.userId) >= 0) found = true
//         if (!found) {
//           memberArr.push(item.userId)
//           groupInfo.user.members = ',' + memberArr.join(',') + ','

//           localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//         }
//       }
//     },
//     /**
//      * 添加用户
//      *
//      * @param state
//      * @param item
//      */
//     addChatContact (state, item) {
//       const chatId = getChatId(0, item.id)
//       const result = state.friendlist.find((userInfo) => userInfo.id == chatId)

//       if (result === null) {
//         state.friendlist.push({
//           id: chatId,
//           userId: item.id,
//           nickName: item.nickName,
//           name: item.name,
//           phone: item.phone,
//           avatar: item.avatar,
//           addTime: item.modifyTime,
//           status: 0,
//           targetId: 0,
//           talkType: 0,
//           remarks: '',
//           memberIds: '',
//           lastTime: Date.now()
//         })
//       }
//     },
//     /**
//      * 获取用户信息
//      *
//      * @param state
//      * @param item
//      * @returns {*}
//      */
//     getChatContact (state, chatId) {
//       const userInfo = state.friendlist.find((user) => user.id == chatId)
//       console.log(userInfo)
//       return userInfo
//     },

//     sendResponse (state, json) {
//       const result = state.chatlist.find((session) => session.id == json.toUser)
//       if (result == null) return
//       const message = result.messages.find((msg) => msg.msgId == json.msgId)
//       if (message !== null) {
//         message.sendTime = json.sendTime
//         result.lastTime = json.sendTime

//         // 更新本地数据库状态
//         window.dbHelper.updateSendStatus(state.user.id, json)
//       }

//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },
//     receiveMsg (state, msg) {
//       let targetChat = msg.fromUser

//       let talkType = 0
//       try {
//         talkType = parseInt(msg.toUser.substring(0, 1))
//       } catch (e) {
//         console.log(e)
//         talkType = 0
//       }

//       if (talkType > 0) {
//         targetChat = msg.toUser
//       }
//       const result = state.chatlist.find((session) => session.id == targetChat)

//       msg.self = msg.fromUser == state.user.id

//       if (targetChat !== state.selectId) {
//         result.notifyNum = result.notifyNum !== null ? result.notifyNum + 1 : 1
//       }

//       // 服务器时间
//       result.lastTime = msg.sendTime !== null && msg.sendTime > 0 ? msg.sendTime : Date.now()

//       state.isNotify = !msg.self
//       state.notifyNum = state.notifyNum + 1

//       console.log(result.notifyNum + '==========receiveMsg============' + state.isNotify)
//       console.log(msg)

//       if (msg.msgType == 'recall') {
//         // 删除本地记录
//         if (msg.ext !== null && !isEmpty(msg.ext.msgId)) {
//           const message = result.messages.find((item) => item.msgId == msg.ext.msgId)
//           if (message !== null) {
//             message.delete = 1
//             if (message.ext == null) message.ext = { deleteTime: Date.now() }
//             else message.ext.deleteTime = Date.now()
//           }
//         }
//       }

//       // 同步到本地数据库
//       window.dbHelper.saveChatMessage(state.user.id, msg).then(
//         (result) => {
//           console.log(result)
//         },
//         (error) => {
//           console.log(error)
//         }
//       )

//       result.messages.push(msg)

//       localStorage.setItem('chatlist', JSON.stringify(state.chatlist))
//     },
//     setNotifyNum (state, num) {
//       state.notifyNum = num
//     },

//     // 选择好友后，点击发送信息。判断在聊天列表中是否有该好友，有的话跳到该好友对话。没有的话
//     // 添加该好友的对话 并置顶
//     send (state) {
//       console.log('===================send================')
//       const result = state.friendlist.find((friend) => friend.id == state.selectFriendId)
//       const msg = state.chatlist.find((msg) => msg.user.name == result.remark)
//       if (!msg) {
//         state.selectId = 1
//         for (let i = 0; i < state.chatlist.length; i++) {
//           state.chatlist[i].id++
//           state.chatlist[i].index++
//         }
//         state.chatlist.unshift({
//           id: 1,
//           user: {
//             name: result.remark,
//             img: result.img
//           },
//           messages: [
//             {
//               content: '已经置顶聊天，可以给我发信息啦！',
//               date: new Date()
//             }
//           ],
//           index: 1
//         })
//       } else {
//         state.selectId = msg.index
//         // router.push({ path: '/chat'})
//       }
//     }
//   },
//   actions: {
//     search: ({ commit }, value) => {
//       setTimeout(() => {
//         commit('search', value)
//       }, 100)
//     },
//     selectSession: ({ commit }, value) => commit('selectSession', value),
//     selectFriend: ({ commit }, value) => commit('selectFriend', value),
//     sendMessage: ({ commit }, msg) => commit('sendMessage', msg),
//     receiveMsg: ({ commit }, msg) => commit('receiveMsg', msg),
//     sendResponse: ({ commit }, msg) => commit('sendResponse', msg),
//     saveContent: ({ commit }, item) => commit('saveContent', item),

//     // 同步远程数据
//     addMessage: ({ commit }, msg) => commit('addMessage', msg),
//     addChatContact: ({ commit }, msg) => commit('addChatContact', msg),
//     addMeetingInfo: ({ commit }, msg) => commit('addMeetingInfo', msg),
//     getChatContact: ({ commit }, msg) => commit('getChatContact', msg),

//     // 下载文件
//     downloadProgressing: ({ commit }, msg) => commit('downloadProgressing', msg),
//     downloadComplete: ({ commit }, msg) => commit('downloadComplete', msg),
//     downloadError: ({ commit }, msg) => commit('downloadError', msg),

//     // 上传文件
//     uploadProgressing: ({ commit }, msg) => commit('uploadProgressing', msg),
//     uploadComplete: ({ commit }, msg) => commit('uploadComplete', msg),
//     uploadError: ({ commit }, msg) => commit('uploadError', msg),

//     send: ({ commit }) => commit('send'),
//     initData: ({ commit }) => commit('initData'),
//     addChatList: ({ commit }, item) => commit('addChatList', item),
//     addGroupList: ({ commit }, item) => commit('addGroupList', item),
//     updateGroupMember: ({ commit }, item) => commit('updateGroupMember', item),
//     saveGroupMember: ({ commit }, item) => commit('saveGroupMember', item),

//     setUserOffline: ({ commit }, item) => commit('setUserOffline', item),

//     removeChatList: ({ commit }, userId) => commit('removeChatList', userId),

//     // 会议/群组方法
//     addRoomMember: ({ commit }, item) => commit('addRoomMember', item),
//     removeRoomMember: ({ commit }, item) => commit('removeRoomMember', item),

//     setChatList (context, chatList) {
//       context.commit('chatList', chatList)
//     },
//     setUser: ({ commit }, userInfo) => commit('setUser', userInfo),
//     setNotifyNum: ({ commit }, value) => commit('setNotifyNum', value)
//   }
// }
