import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-26c37add"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav-bar"
};
const _hoisted_2 = {
  class: "nav-bar--left"
};
const _hoisted_3 = {
  class: "nav-bar--title"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "nav-bar--right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["nav-bar--content nav-bar--fixed", {
      ['navbar-border']: $data.border
    }])
  }, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "left", {}, undefined, true)]), _createElementVNode("div", _hoisted_3, [$props.title ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$props.title), 1)) : _renderSlot(_ctx.$slots, "title", {
    key: 1
  }, undefined, true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "right", {}, undefined, true)])], 2)]);
}