export function uuid() {
    var d = new Date().getTime()
    if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now() //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c
    ) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid.replaceAll('-', '')
}

export function compare(key, desc) {
    //key:  用于排序的数组的key值
    //desc： 布尔值，为true是升序排序，false是降序排序
    return function (a, b) {
        let value1 = a[key];
        let value2 = b[key];
        if (desc == true) {
            // 升序排列
            return value1 - value2;
        } else {
            // 降序排列
            return value2 - value1;
        }
    };
}

/**
 * 校验只要是数字（包含正负整数，0以及正负浮点数）就返回true
 *
 * @param val
 * @returns {boolean}
 */
export function isNumber(val){
    let regPos = /^\d+(\.\d+)?$/; //非负浮点数
    let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
    //负浮点数
    if(regPos.test(val) || regNeg.test(val)){
        return true;
    }else{
        return false;
    }
}

export function trim(str) {  //删除左右两端的空格
    if(str==null) return '';
    if(isNumber(str))
        return str+'';

    return str.replace(/(^\s*)|(\s*$)/g, "");
}

export function ltrim(str) {  //删除左边的空格
    if(str==null) return '';
    if(typeof value === 'number' && !isNaN(value))
        return str;
    return str.replace(/(^\s*)/g,"");
}

export function  rtrim(str) {  //删除右边的空格
    if(str==null) return '';
    if(typeof value === 'number' && !isNaN(value))
        return str;
    return str.replace(/(\s*$)/g,"");
}

export function isEmpty(src) {
    if(src==null || src=='null' || src=='undefined' || src=='NaN' || src=='') {
        return true;
    }

    return false;
}

export function isEq(src, dest) {
    let result = false;

    if(src==dest)
        result = true;

    return  result;
}
// 根据id查找treeList的节点
export function findById(list, id) {
    let item = null;

    try {
        list.forEach(x => {
            if (x.id == id) {
                item = x;
                throw Error();
            }

            if (x.children && x.children.length > 0) {
                item = findById(x.children, id);
                if(item != null)
                    throw Error();
            }
        });
    }
    catch (e) {
    }

    return item;
}

export function getChatId(type, userId) {
    // 0:1v1 1:群聊 2:会议
    let chatId = type + '-' + userId;

    return chatId;
}

// list转treeList
export function makeTreeList(list) {
    list.forEach(r => {
        let ary = list.filter(f => f.parentId === r.value)
        if (ary) {
            r.children = ary
        }
    });

    return list.filter(r => r.parentId == 0);
}

// 获取容器dom
export function getContainer(target) {
    if(target==null) return null;
    if(target.className.includes('epc-editor-container') || target.className.includes('epc-container-item')
        || target.className.includes('epc_table_box') || target.className.includes('thead-td'))
        return target;

    let count = 0;
    while (count<10) {
        target = target.parentNode;
        if(target.className.includes('epc-editor-container') || target.className.includes('epc-container-item')
            || target.className.includes('epc_table_box') || target.className.includes('thead-td'))
            return target;
        count++;
    }
    return null;
}

//修改控件属性
export function setUIAttribute(uiItem, attrType, keyName, value) {
    let found = false;
    // 1.1 私有属性公用处理逻辑
    if(attrType>=1) {
        // 一级属性
        if (keyName == 'text' || keyName == 'row') {
            uiItem[keyName] = value;
            uiItem['myAttr'][keyName] = value;
        }
        else if (keyName == 'column-allow') {
            try {
                uiItem[keyName] = parseInt(value);
            } catch (e) {
            }
        }
        else {
            // 二级属性
            if (uiItem['myAttr'] == null) uiItem['myAttr'] = {};

            if (keyName == 'allow-edit') {
                try {
                    uiItem['myAttr'][keyName] = parseInt(value);
                } catch (e) {
                }
            } else if (keyName == 'table-col' || keyName == 'table-row') {
                let num = 0;
                try {
                    num = value == '' ? 1 : parseInt(value);
                } catch (e) {
                    num = 1;
                }
                uiItem['myAttr'][keyName] = num;

                if (keyName == 'table-col') {
                    let col_size = uiItem['thead'].length;
                    // 小于原来列数，删除
                    console.log(col_size + '>' + uiItem['myAttr'][keyName])
                    if (col_size > num) {
                        uiItem['thead'].splice(num, col_size - num);
                    }
                    // 大于原来列数新增
                    else if (col_size < num) {
                        for (let i = col_size; i < num; i++) {
                            this.addTableCol(uiItem['thead'], i, '列' + i);
                        }
                    }
                }
            } else
                uiItem['myAttr'][keyName] = value;
        }
    }
    else {
        // 1.2 UI样式
        if (uiItem.attrs == null || uiItem.attrs.length <= 0) {
            uiItem.attrs = [];
        }

        uiItem.attrs.forEach(x => {
            let name = trim(x.name);
            if (name == keyName) {
                found = true;
                x.value = value;
            }
        });

        if (!found) {
            let attrs = {id: uiItem.attrs.length + 1, name: keyName, value: value, type: 0};
            uiItem.attrs.push(attrs);
        }
    }

    return found;
}

// 设置dom样式
export function setUIStyle(uiItem, attrType, keyName, valueType, valueUnit, value) {
    // 1.1 私有属性
    if(uiItem==null || uiItem.myTarget==null || (uiItem.viewType>1 && attrType>0) ) {
        // 不处理
        return;
    }
    else {
        let target = getContainer(uiItem.myTarget);
        if(target==null) return;

        let myStyle = target.getAttribute('style');
        let myStyles = [];
        let found = false;

        if(value!='' && isNumber(value)) {
            value = value + valueUnit;
        }

        if(myStyle!=null && myStyle!='') {
            let styleArr = myStyle.split(";")
            for(let i=0; i<styleArr.length;i++) {
                let arr = styleArr[i].split(":");
                if(arr.length>1) {

                    if(trim(arr[0])=='keyName') {
                        found = true;
                        if(value!='') {
                            myStyles.push(keyName + ': '+ value)
                        }
                    }
                    else {
                        myStyles.push(styleArr[i])
                    }
                }
            }
        }

        if(!found && value!='')
            myStyles.push(keyName + ': '+ value)

        target.style.cssText = myStyles.join('; ');
    }
}

// 匹配变量与传参值
export function getQueryValue(queryList, valueStr) {
    let queryReg =/\$\{(.+?)\}/;

    let result = '';

    let arr = valueStr.match(queryReg);
    if(arr!=null && arr.length>1) {
        let key = arr[1].trim();
        if(queryList[key]!=null) {
            result = queryList[key];
        }
    }

    return result;
}

// 匹配变量与传参值
export function getValue(valueMap, fieldValue) {
    let pattern = new RegExp('\\\$\{([a-z,A-Z,0-9,_]+)\}')

    let value = fieldValue;
    let arr = pattern.exec(fieldValue);

    if(arr!=null) {
        let key = arr[1];
        if(valueMap!=null && valueMap[key]!=null) {
            value = valueMap[key];
        }
        else {
            value = '';
        }
    }

    return value;
}
/**
 * URL编码;
 * @param {参数} param
 */
export function toParams(param) {
    var result = ""
    for (let name in param) {
        if (typeof param[name] != 'function') {
            result += "&" + name + "=" + encodeURI(param[name]);
        }
    }
    return result.substring(1)
}

export function getNickName(userInfo) {
    let title = userInfo==null?'-':!isEmpty(userInfo.nickName)?userInfo.nickName:!isEmpty(userInfo.name)?userInfo.name:userInfo.phone;

    return title;
}
export function getStreamId(node) {
    let streamId = node.getAttribute('id');
    if(streamId==null) return null;
    streamId = streamId.substring(0, streamId.length-'_item'.length);

    return streamId;
}

export function clearHtml(content) {
    console.log('=================clearHtml======================')

    let regex = /<(?!img|p|\/p|br).*?>/g;
    content = content.replaceAll('<div><br><\/div>', '<br />').replaceAll('<div>', '<br />').replaceAll('<\/div>', '<br />');
    for(let i=0;i<10;i++)
        content = content.replaceAll('<br /><br />', '<br />').replaceAll('<br /><br />', '<br />');

    let result = content.replace(regex, "");

    return result;
}

export function clearAllHtml(content) {
    let regex =  /(<([^>]+)>)/ig;
    let result = content.replace(regex, "");

    return result;
}

export function onlineCompare (x, y) {
    if (x.isOnline && y.isOnline) {
        return 0;
    } else if (x.isOnline) {
        return -1;
    } else if (y.isOnline) {
        return 1;
    } else {
        return 0;
    }
}

export function getBoxInfo(width, height, mScale) {
    let roomSize = {width: width, height: height};

    if(mScale!=null) {
        height = width/mScale;
        roomSize.height = height;
    }

    let maxHeight = screen.availHeight;
    // let maxWidth = screen.availWidth;

    if(maxHeight<height) {
        height = maxHeight - 100;
        if(mScale!=null)
            width = height * mScale;
        else
            width = height *  (width/height);

        roomSize.width = width;
        roomSize.height = height;
    }

    return roomSize;
}

export function getMemberIds(memberIds) {
    let memberArr = [];
    let arr = isEmpty(memberIds)?[]:memberIds.split(',');
    arr.forEach(uid=> {
        if(!isEmpty(uid))
            memberArr.push(parseInt(uid));
    })

    return memberArr;
}

export function getMsgIsShow(msgType) {
    let isShow = false;

    switch (msgType) {
        case 'img':
        case 'file':
        case 'video':
        case 'recall':
        case 'text':
            isShow = true;
            break;
        default:
            break;
    }
    return isShow;

}