import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3828d728"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "inputButton"
};
const _hoisted_2 = {
  class: "inputButton_content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
    class: "button",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/search'))
  }, [_createVNode(_component_van_icon, {
    name: "search"
  }), _createElementVNode("span", null, " " + _toDisplayString($props.text), 1)])])])]);
}