import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  return _openBlock(), _createBlock(_component_van_nav_bar, {
    border: true,
    placeholder: true,
    "left-arrow": true,
    "left-text": '',
    onClickLeft: $options.onClickLeft,
    fixed: "",
    title: $props.title,
    "right-text": $props.rightText,
    onClickRight: $options.onClickRight
  }, null, 8, ["onClickLeft", "title", "right-text", "onClickRight"]);
}