import {isEmpty} from "@/utils/StringUtils";

function parseInitState(key) {
    return sessionStorage.getItem(key) !=null? sessionStorage.getItem(key) : '';
}

function parseUserState(key) {
    return localStorage.getItem(key) !=null? localStorage.getItem(key) : '';
}

export default{
    state: {
        username:sessionStorage.getItem('username'), //登录名
        token: sessionStorage.getItem("token") || '', //token
        isSyncLocal: sessionStorage.getItem("isSyncLocal") || 0, // 同步本地数据
        userInfo: parseInitState('userInfo'),
        onlineList: parseInitState('onlineList'),
        loginHistory: parseUserState('loginHistory') || [],
        isResizeWindow: false,
        autoLogin: parseUserState('autoLogin') || '0',
        platform: parseUserState('platform') || '',
        clientId: parseInitState('clientId') || '',
        isTop: parseInitState('isTop') || '0',
        lastToUser:parseInitState('lastToUser') || {'toUser': '', 'toMeeting': ''},
      },
      getters: {
          getIsSyncLocal: function (state) {
              let info = state.isSyncLocal;
              if(isEmpty(info)) {
                  return 0;
              }
              return info;
          },
          getLoginHistory: function (state) {
              let info = state.loginHistory;
              if(isEmpty(info)) {
                  return [];
              }
              else if(info instanceof Object) {
                  return info;
              }
              else {
                  return JSON.parse(info);
              }
          },
          getAutoLogin: function (state) {
              let info = state.autoLogin;
              if(isEmpty(info)) {
                  return '0';
              }
              return info;
          },
          getOnlineList: function (state) {
              let info = state.onlineList;
              if(isEmpty(info)) {
                  return [];
              }
              else if(info instanceof Object) {
                  return info;
              }
              else {
                  return JSON.parse(info);
              }
          },
          getUserInfo: function (state) {
              let info = state.userInfo;
              if(isEmpty(info)) {
                  return {};
              }
              else if(typeof info=='string') {
                  return JSON.parse(info);
              }
              else {
                  return info;
              }
          },
          getPlatform: function (state) {
              let info = state.platform;
              if(isEmpty(info)) {
                  return '';
              }
              return info;
          },
          getClientId: function (state) {
              let info = state.clientId;
              if(isEmpty(info)) {
                  return '';
              }
              return info;
          },
          getIsTop: function (state) {
              let info = state.isTop;
              if(isEmpty(info)) {
                  return '0';
              }
              return info;
          },
          getLastToUser: function (state) {
              let info = state.lastToUser;
              console.log(info)
              if(isEmpty(info)) {
                  return {'toUser': '', 'toMeeting': ''};
              }
              else if(typeof info=='string') {
                  return JSON.parse(info);
              }
              else {
                  return info;
              }
          },
      },
      mutations: {
          setName(state,val){
              state.username = val;

              if(val == null || val=='')
                  sessionStorage.removeItem("username");
              else
                  sessionStorage.setItem("username", val);
        },
        setToken(state, val) {
            state.token = val

            if(val == null || val=='')
                sessionStorage.removeItem("token");
            else
                sessionStorage.setItem("token", val);
        },
          isSyncLocal(state, val) {
            state.isSyncLocal = val

            if(val == null || val=='')
              sessionStorage.removeItem("isSyncLocal");
            else
              sessionStorage.setItem("isSyncLocal", val);
        },
        onlineList(state, val) {
            state.onlineList = val;
            if(val == null || val=='')
                sessionStorage.removeItem("onlineList");
            else
                sessionStorage.setItem("onlineList", JSON.stringify(val));
        },
        userInfo(state, val) {
            state.userInfo = val

            if(val == null || val=='')
                sessionStorage.removeItem("userInfo");
            else
                sessionStorage.setItem("userInfo", JSON.stringify(val));
        },
        loginHistory(state, val) {
          state.loginHistory = val;
          if(val == null || val=='')
              localStorage.removeItem("loginHistory");
          else
              localStorage.setItem("loginHistory", JSON.stringify(val));
        },
        autoLogin(state, val) {
          state.autoLogin = val;
          if(val == null || val=='')
              localStorage.removeItem("autoLogin");
          else
              localStorage.setItem("autoLogin", val);
        },
        platform(state, val) {
          state.platform = val;
          if(val == null || val=='')
              localStorage.removeItem("platform");
          else
              localStorage.setItem("platform", val);
        },
        clientId(state, val) {
          state.clientId = val;
          if(val == null || val=='')
              sessionStorage.removeItem("clientId");
          else
              sessionStorage.setItem("clientId", val);
        },
        isTop(state, val) {
          state.isTop = val;
          if(val == null || val=='')
              sessionStorage.removeItem("isTop");
          else
              sessionStorage.setItem("isTop", val);
        },
        lastToUser(state, val) {
          state.lastToUser = val;
          if(val == null || val=='')
              sessionStorage.removeItem("lastToUser");
          else
              sessionStorage.setItem("lastToUser", JSON.stringify(val));
        },
      },
      actions: {
         setName(context,username){
          context.commit('setName',username)
         },
         setToken(context,token){
          context.commit('setToken',token)
        },
        setIsSyncLocal(context, isSyncLocal){
          context.commit('isSyncLocal',isSyncLocal)
        },
        setOnlineList(context,onlineList){
          context.commit('onlineList',onlineList)
        },
        setUserInfo(context,userInfo){
            context.commit('userInfo',userInfo)
        },
        setLoginHistory(context, loginHistory){
          context.commit('loginHistory',loginHistory)
        },
        addLoginHistory(context, item) {
            let loginHistory = context.getters['getLoginHistory'];
            let arr = loginHistory.filter(r=>r.username===item.username);
            if(arr.length<=0) {
                item.time = Date.now();
                loginHistory.push(item);
            }
            else {
                arr[0].time = Date.now();
                arr[0].password = item.password;
                arr[0].type = item.type;
            }

            context.commit('loginHistory',loginHistory)
        },
        delLoginHistory(context, item) {
          let loginHistory = context.getters['getLoginHistory'];
          let index = loginHistory.findIndex(r=>r.username===item.username);

          if(index>=0) {
              loginHistory.splice(index, 1);
              context.commit('loginHistory',loginHistory)
          }

        },
        setAutoLogin(context, autoLogin){
          context.commit('autoLogin',autoLogin)
        },
        setPlatform(context, platform){
          context.commit('platform',platform)
        },
        setClientId(context, clientId){
          context.commit('clientId',clientId)
        },
        setIsTop(context, isTop){
          context.commit('isTop',isTop)
        },
        setLastToUser(context, lastToUser){
          context.commit('lastToUser',lastToUser)
        },
      }
    }

// import { isEmpty } from '@/utils/StringUtils'

// function parseInitState (key) {
//   return sessionStorage.getItem(key) != null ? sessionStorage.getItem(key) : ''
// }

// function parseUserState (key) {
//   return localStorage.getItem(key) != null ? localStorage.getItem(key) : ''
// }

// export default {
//   state: {
//     username: sessionStorage.getItem('username'), // 登录名
//     token: sessionStorage.getItem('token') || '', // token
//     isSyncLocal: sessionStorage.getItem('isSyncLocal') || 0, // 同步本地数据
//     userInfo: parseInitState('userInfo'),
//     onlineList: parseInitState('onlineList'),
//     loginHistory: parseUserState('loginHistory') || [],
//     isResizeWindow: false,
//     autoLogin: parseUserState('autoLogin') || '0',
//     platform: parseUserState('platform') || '',
//     clientId: parseInitState('clientId') || '',
//     isTop: parseInitState('isTop') || '0',
//     lastToUser: parseInitState('lastToUser') || { toUser: '', toMeeting: '' }
//   },
//   getters: {
//     getIsSyncLocal: function (state) {
//       const info = state.isSyncLocal
//       if (isEmpty(info)) {
//         return 0
//       }
//       return info
//     },
//     getLoginHistory: function (state) {
//       const info = state.loginHistory
//       if (isEmpty(info)) {
//         return []
//       } else if (info instanceof Object) {
//         return info
//       } else {
//         return JSON.parse(info)
//       }
//     },
//     getAutoLogin: function (state) {
//       const info = state.autoLogin
//       if (isEmpty(info)) {
//         return '0'
//       }
//       return info
//     },
//     getOnlineList: function (state) {
//       const info = state.onlineList
//       if (isEmpty(info)) {
//         return []
//       } else if (info instanceof Object) {
//         return info
//       } else {
//         return JSON.parse(info)
//       }
//     },
//     getUserInfo: function (state) {
//       const info = state.userInfo
//       if (isEmpty(info)) {
//         return {}
//       } else if (typeof info === 'string') {
//         return JSON.parse(info)
//       } else {
//         return info
//       }
//     },
//     getPlatform: function (state) {
//       const info = state.platform
//       if (isEmpty(info)) {
//         return ''
//       }
//       return info
//     },
//     getClientId: function (state) {
//       const info = state.clientId
//       if (isEmpty(info)) {
//         return ''
//       }
//       return info
//     },
//     getIsTop: function (state) {
//       const info = state.isTop
//       if (isEmpty(info)) {
//         return '0'
//       }
//       return info
//     },
//     getLastToUser: function (state) {
//       const info = state.lastToUser
//       console.log(info)
//       if (isEmpty(info)) {
//         return { toUser: '', toMeeting: '' }
//       } else if (typeof info === 'string') {
//         return JSON.parse(info)
//       } else {
//         return info
//       }
//     }
//   },
//   mutations: {
//     setName (state, val) {
//       state.username = val

//       if (val === null || val === '') sessionStorage.removeItem('username')
//       else sessionStorage.setItem('username', val)
//     },
//     setToken (state, val) {
//       state.token = val

//       if (val === null || val === '') sessionStorage.removeItem('token')
//       else sessionStorage.setItem('token', val)
//     },
//     isSyncLocal (state, val) {
//       state.isSyncLocal = val

//       if (val === null || val === '') sessionStorage.removeItem('isSyncLocal')
//       else sessionStorage.setItem('isSyncLocal', val)
//     },
//     onlineList (state, val) {
//       state.onlineList = val
//       if (val === null || val === '') sessionStorage.removeItem('onlineList')
//       else sessionStorage.setItem('onlineList', JSON.stringify(val))
//     },
//     userInfo (state, val) {
//       state.userInfo = val

//       if (val === null || val === '') sessionStorage.removeItem('userInfo')
//       else sessionStorage.setItem('userInfo', JSON.stringify(val))
//     },
//     loginHistory (state, val) {
//       state.loginHistory = val
//       if (val === null || val === '') localStorage.removeItem('loginHistory')
//       else localStorage.setItem('loginHistory', JSON.stringify(val))
//     },
//     autoLogin (state, val) {
//       state.autoLogin = val
//       if (val === null || val === '') localStorage.removeItem('autoLogin')
//       else localStorage.setItem('autoLogin', val)
//     },
//     platform (state, val) {
//       state.platform = val
//       if (val === null || val === '') localStorage.removeItem('platform')
//       else localStorage.setItem('platform', val)
//     },
//     clientId (state, val) {
//       state.clientId = val
//       if (val === null || val === '') sessionStorage.removeItem('clientId')
//       else sessionStorage.setItem('clientId', val)
//     },
//     isTop (state, val) {
//       state.isTop = val
//       if (val === null || val === '') sessionStorage.removeItem('isTop')
//       else sessionStorage.setItem('isTop', val)
//     },
//     lastToUser (state, val) {
//       state.lastToUser = val
//       if (val === null || val === '') sessionStorage.removeItem('lastToUser')
//       else sessionStorage.setItem('lastToUser', JSON.stringify(val))
//     }
//   },
//   actions: {
//     setName (context, username) {
//       context.commit('setName', username)
//     },
//     setToken (context, token) {
//       context.commit('setToken', token)
//     },
//     setIsSyncLocal (context, isSyncLocal) {
//       context.commit('isSyncLocal', isSyncLocal)
//     },
//     setOnlineList (context, onlineList) {
//       context.commit('onlineList', onlineList)
//     },
//     setUserInfo (context, userInfo) {
//       context.commit('userInfo', userInfo)
//     },
//     setLoginHistory (context, loginHistory) {
//       context.commit('loginHistory', loginHistory)
//     },
//     addLoginHistory (context, item) {
//       const loginHistory = context.getters.getLoginHistory
//       const arr = loginHistory.filter((r) => r.username === item.username)
//       if (arr.length <= 0) {
//         item.time = Date.now()
//         loginHistory.push(item)
//       } else {
//         arr[0].time = Date.now()
//         arr[0].password = item.password
//         arr[0].type = item.type
//       }

//       context.commit('loginHistory', loginHistory)
//     },
//     delLoginHistory (context, item) {
//       const loginHistory = context.getters.getLoginHistory
//       const index = loginHistory.findIndex((r) => r.username === item.username)

//       if (index >= 0) {
//         loginHistory.splice(index, 1)
//         context.commit('loginHistory', loginHistory)
//       }
//     },
//     setAutoLogin (context, autoLogin) {
//       context.commit('autoLogin', autoLogin)
//     },
//     setPlatform (context, platform) {
//       context.commit('platform', platform)
//     },
//     setClientId (context, clientId) {
//       context.commit('clientId', clientId)
//     },
//     setIsTop (context, isTop) {
//       context.commit('isTop', isTop)
//     },
//     setLastToUser (context, lastToUser) {
//       context.commit('lastToUser', lastToUser)
//     }
//   }
// }
