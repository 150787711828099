import { Dexie } from 'dexie'
import { getChatId, getMemberIds, isEmpty, uuid } from '@/utils/StringUtils'

/**
 * IndexedDB本地数据库
 *
 * 1.创建数据表，根据版本新增数据表
 * 2.登陆时，同步本地store数据到本地数据表；远程数据同步到本地数据表（同时同步到store）
 * 3.登录后，本地数据同步到store
 * 4.发送、接收同步到本地数据表
 * 5.文件上传、下载更新本地数据表
 * 6.数据格式转换: remoteDB->localDB、remoteDB->storeDB、storeDB->localDB、localDB->storeDB
 */
export default class IndexedDBHelp {
  init () {
    this._db = null
    this._info = {
      dbName: 'epc_im_db',
      // 实际建库的时候版本号都会乘以10，如果这里lastVersion传0.1，实际建的库的版本就是 1
      version: 0.1
    }
  }

  constructor () {
    console.log('=====================IndexedDBHelp=====================')
    this.myIndexedDB =
      window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB
    if (!this.myIndexedDB) {
      console.log('您的浏览器不支持 IndexedDB')
      return
    }

    // 初始化成员
    this.init()

    // 初始化数据库
    this.initDb()

    // 初始化App
    this.initApp()
  }

  initDb () {
    // this.deleteTable(this._info.dbName);
    this._db = new Dexie(this._info.dbName)
    this.checkVersion()
  }

  initApp () {
    console.log('==========initApp==============')
    this._db.appInfo.get(1).then(
      (info) => {
        console.log(info)
        if (info === null) {
          this._db.appInfo.put({
            id: 1,
            clientId: uuid(),
            appVersion: '1.0.0',
            lastTime: 0
          })
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  checkVersion () {
    if (this._db != null) {
      let version = this._info.version

      this._db.version(version).stores({
        // 主键自增（++）, 唯一索引(&),多值索引(*),复合索引[a+b]
        chatMessage:
          '++id, userId, msgId, [userId+msgId], fromUserId, targetId, [fromUserId+targetId], msgStatus, msgType, content, sendStatus, downStatus, talkType, addTime, sendTime, readTime, ext, delete',
        chatContact:
          '++id, userId, targetId, [userId+targetId], talkType, [userId+talkType], nickName, name, phone, avatar, addTime, modifyTime, status, remarks, memberIds',
        appInfo: '++id, clientId, appVersion, lastTime',
        msgStatus: '++id, clientId, userId, [clientId+userId], lastTime'
      })

      version = version + 0.1
      this._db.version(version).stores({
        // 主键自增（++）, 唯一索引(&),多值索引(*),复合索引[a+b]
        chatContact:
          '++id, userId, targetId, [userId+targetId], talkType, [userId+talkType], nickName, name, phone, avatar, addTime, modifyTime, status, remarks, memberIds'
      })

      version = version + 0.2
      this._db.version(version).stores({
        // 主键自增（++）, 唯一索引(&),多值索引(*),复合索引[a+b]
        chatMessage:
          '++id, userId, msgId, [userId+msgId], fromUserId, targetId, [fromUserId+targetId], msgStatus, msgType, content, sendStatus, downStatus, talkType, addTime, sendTime, readTime, ext, delete'
      })

      // this.testSql();
    }
  }

  /**
   * 删除数据表
   *
   * @param tableName
   */
  deleteTable (tableName) {
    if (isEmpty(tableName)) return

    if (this._db != null) this._db.close()

    Dexie.delete(tableName).then(
      (result) => {
        console.log(result)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
   * 添加记录
   *
   * @param item
   * @returns {Promise<TKey>}
   */
  addChatMessage (item) {
    if (item === null) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    } else return this._db.chatMessage.put(item)
  }

  /**
   * 增、改：推荐使用put添加，而不是add
   *
   * @param msgId
   * @param item
   */
  updateChatMessage (userId, item) {
    return new Promise((resolve, reject) => {
      try {
        this._db.chatMessage
          .where('[userId+msgId]')
          .anyOf([[userId, item.msgId]])
          .toArray()
          .then((data) => {
            if (data.length > 0) {
              this._db.chatMessage.put(item).then((result) => {
                resolve(result)
              })
            } else {
              reject(new Error(-1))
            }
          })
      } catch (e) {
        console.log(e)
        reject(new Error(-1))
      }
    })
  }

  /**
   * 获取消息
   *
   * @param msgId
   * @returns {Promise<*>}
   */
  async getMessageByMsgId (userId, msgId) {
    const item = await this._db.chatMessage
      .where('[userId+msgId]')
      .anyOf([[userId, msgId]])
      .toArray()
    if (item.length > 0) return item[0]

    return null
  }

  /**
     * 获取客户端clientId
     *
     * @returns {Promise<unknown>}
     */
  getClientId() {
    return new Promise(async (resolve, reject) => {
        try {
            let info = await this._db.appInfo.get(1);
            if(info==null) {
                info = {
                    'id': 1,
                    'clientId': uuid(),
                    'appVersion': '1.0.0',
                    'lastTime': 0,
                };
                this._db.appInfo.put(info);
            }
            resolve(info.clientId);
        }
        catch (e) {
            reject(e);
        }
    });
}

  /**
   * 更新同步数据状态
   *
   * @param clientId
   * @param userId
   * @param lastTime
   */
  updateMsgStatus (clientId, userId, lastTime) {
    this._db.msgStatus
      .where({ clientId: clientId })
      .and((item) => item.userId === userId)
      .toArray()
      .then(
        (data) => {
          if (data.length > 0) {
            data[0].lastTime = lastTime
            this._db.msgStatus.put(data[0])
          } else {
            this._db.msgStatus.put({
              clientId: clientId,
              userId: userId,
              lastTime: lastTime
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
  }

  /**
   * 获取同步数据状态
   *
   * @param clientId
   * @param userId
   * @param callback
   */
  async getMsgStatus (clientId, userId, callback) {
    // let arr = await this._db.msgStatus.where({'clientId': clientId}).and(item=> item.userId===userId).toArray();

    const item = await this._db.msgStatus.where({ clientId: clientId, userId: userId }).first()
    return item
  }

  // 保存发送消息到本地数据库
  saveChatMessage (userId, msg) {
    const item = this.msgToChatMessage(userId, msg)
    return this.addChatMessage(item)
  }

  /**
   * 更新发送状态
   *
   * @param json
   */
  updateSendStatus (userId, json) {
    this._db.chatMessage
      .where('[userId+msgId]')
      .anyOf([[userId, json.msgId]])
      .toArray()
      .then((arr) => {
        if (arr.length > 0) {
          arr[0].sendTime = json.sendTime
          arr[0].sendStatus = json.sendStatus
          this._db.chatMessage.put(arr[0])
        }
      })
  }

  /**
   * 更新下载状态
   *
   * @param json
   */
  updateDownStatus (userId, json) {
    this._db.chatMessage
      .where('[userId+msgId]')
      .anyOf([[userId, json.msgId]])
      .toArray()
      .then((arr) => {
        if (arr.length > 0) {
          arr[0].downStatus = json.downStatus

          if (arr[0].ext === null) arr[0].ext = {}
          arr[0].ext.downTime = json.downTime

          if (json.downStatus === 1) {
            arr[0].ext.srcFile = json.srcFile
            arr[0].ext.srcFileUrl = json.srcFileUrl
          }

          this._db.chatMessage.put(arr[0])
        }
      })
  }

  /**
   * 更新上传状态
   *
   * @param userId
   * @param json
   */
  updateUploadStatus (userId, json) {
    this._db.chatMessage
      .where('[userId+msgId]')
      .anyOf([[userId, json.msgId]])
      .toArray()
      .then((arr) => {
        if (arr.length > 0) {
          arr[0].sendStatus = json.sendStatus
          if (arr[0].ext === null) arr[0].ext = {}

          arr[0].ext.lastTime = json.lastTime

          if (json.sendStatus === 1) {
            arr[0].ext.fileUrl = json.fileUrl
            arr[0].ext.fileId = json.fileId
          }

          this._db.chatMessage.put(arr[0])
        }
      })
  }

  /**
   * 删除
   *
   * @param msgId
   * @returns {Promise<unknown>}
   */
  deleteChatMessage (userId, msgId) {
    return new Promise((resolve, reject) => {
      try {
        this._db.chatMessage
          .where('[userId+msgId]')
          .anyOf([[userId, msgId]])
          .then((data) => {
            if (data.length > 0) {
              this._db.chatMessage.delete(data[0].id).then((result) => {
                resolve(result)
              })
            } else {
              reject(new Error(-1))
            }
          })
      } catch (e) {
        console.log(e)
        reject(new Error(-1))
      }
    })
  }

  /**
   * 获取当前会话记录
   * equals(targetId).reverse().limit(30).sortBy("id")
   *
   * @param myChatId
   * @param targetId
   * @returns {Promise<unknown>}
   */
  async findByTargetId (myChatId, targetId) {
    const that = this
    const userId = parseInt(myChatId.replace('0-', 0))

    console.log(myChatId + ',' + targetId)
    const res = await that._db.chatMessage
      .where('[fromUserId+targetId]')
      .anyOf([
        [myChatId, targetId],
        [targetId, myChatId]
      ])
      .and((item) => item.userId == userId)
      .and((item) => item.delete == 0)

      .reverse()
      .limit(30)
      .sortBy('sendTime')

    const list = []
    res.forEach((r) => {
      list.push(this.chatMessageToMsg(myChatId, r))
    })

    return list
    
    // return new Promise((resolve, reject) => {
    //   try {
        // const list = []
        
        // console.log(that._db.chatMessage
        //   .where('[fromUserId+targetId]')
        //   .anyOf([
        //     [myChatId, targetId],
        //     [targetId, myChatId]
        //   ])
        //   .and((item) => item.userId === userId)
        //   .and((item) => item.delete === 0)
        //   .reverse()
        //   .limit(30)
        //   .sortBy('sendTime')
        // );
        // that._db.chatMessage
        // .where('[fromUserId+targetId]')
          // .anyOf([
          //   [myChatId, targetId],
          //   [targetId, myChatId]
          // ])
          // .and((item) => item.userId === userId)
          // .and((item) => item.delete === 0)

          // .reverse()
          // .limit(30)
          // .sortBy('sendTime')
          // .then(
          //   (arr) => {
          //     arr.forEach((r) => {
          //       list.push(this.chatMessageToMsg(myChatId, r))
          //     })

          //     resolve(list.reverse())
          //   },
          //   (error) => {
          //     reject(error)
          //   }
          // )
      // } catch (e) {
      //   reject(e)
      // }
    // })
  }

  /**
   * 同步获取最新记录
   *
   * @param myChatId
   * @param targetId
   * @returns {Promise<*[]>}
   */
  async FindByTargetId (myChatId, targetId) {
    const that = this
    const userId = parseInt(myChatId.replace('0-', 0))

    const arr = await that._db.chatMessage
      .where('[fromUserId+targetId]')
      .anyOf([
        [myChatId, targetId],
        [targetId, myChatId]
      ])
      .and((item) => item.userId == userId)
      .and((item) => item.delete == 0)

      .reverse()
      .limit(30)
      .sortBy('sendTime')

    const list = []
    arr.forEach((r) => {
      list.push(this.chatMessageToMsg(myChatId, r))
    })

    return list
  }

  /**
   * 获取会话类型
   *
   * @param targetId
   * @returns {number}
   */
  getTalkType (targetId) {
    let talkType = 0

    if (targetId.indexOf('1-') >= 0) {
      talkType = 1
    } else if (targetId.indexOf('2-') >= 0 || targetId.indexOf('3-') >= 0) {
      talkType = 2
    }

    return talkType
  }

  /**
   * 时间戳转日期格式
   *
   * @param time
   * @returns {string}
   */
  filterTime (time) {
    const date = new Date(time)
    const Y = date.getFullYear()
    const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const D = date.getDate()

    const H = this.isAddZero(date.getHours()) // 小时
    const mm = this.isAddZero(date.getMinutes()) // 分钟
    const S = this.isAddZero(date.getSeconds()) // 秒

    return `${Y}-${M}-${D} ${H}:${mm}:${S}`
  }

  /**
   * 判断时间是否小于10，小于10 前边加0
   *
   * @param time
   * @returns {string|string}
   */
  isAddZero (time) {
    const str = time < 10 ? '0' + time : time.toString()
    return str
  }

  /**
   * 日期转时间戳
   *
   * @param data
   * @returns {number}
   */
  dateToTime (data) {
    return new Date(data).getTime()
  }

  /**
   * 时间戳转日期
   *
   * @param time
   * @returns {Date}
   */
  timeToDate (time) {
    return new Date(time)
  }

  /**
   * 导入本地localStore聊天记录
   *
   * @param chatList
   */
  importLocalStore (userId, chatList) {
    return new Promise((resolve, reject) => {
      try {
        let count = 0

        if (chatList === null || chatList.length <= 0) return resolve(count)
        chatList.forEach((user) => {
          if (user.messages != null && user.messages.length > 0) {
            user.messages.forEach((msg) => {
              if (isEmpty(msg.msgId)) msg.msgId = uuid()

              this._db.chatMessage
                .where('[userId+msgId]')
                .anyOf([[userId, msg.msgId]])
                .toArray()
                .then((data) => {
                  if (data.length <= 0) {
                    count++
                    const row = this.msgToChatMessage(userId, msg)
                    if (row != null) {
                      this._db.chatMessage.put(row).then((id) => {
                        msg.id = id
                      })
                    }
                  }
                })
            })
          }
        })

        return resolve(count)
      } catch (e) {
        resolve(0)
      }
    })
  }

  /**
   * 同步导入本地localStore聊天记录
   *
   * @param chatList
   */
  syncImportLocalStore (userId, chatList) {
    return new Promise((resolve, reject) => {
      try {
        let count = 0

        if (chatList === null || chatList.length <= 0) return resolve(count)
        for (const user of chatList) {
          if (user.messages != null && user.messages.length > 0) {
            for (const msg of user.messages) {
              if (isEmpty(msg.msgId)) msg.msgId = uuid()

              const data = this._db.chatMessage
                .where('[userId+msgId]')
                .anyOf([[userId, msg.msgId]])
                .toArray()
              if (data.length <= 0) {
                count++
                const row = this.msgToChatMessage(userId, msg)
                if (row != null) {
                  this._db.chatMessage.put(row).then(
                    (id) => {
                      msg.id = id
                    },
                    (error) => {
                      console.log(error)
                    }
                  )
                }
              }
            }
          }
        }

        return resolve(count)
      } catch (e) {
        resolve(0)
      }
    })
  }

  /**
   * 会话消息转ChatMessage
   *
   * @param item
   * @returns {{ext, msgType, targetId, addTime: number, fromUserId, msgId, readTime: number, userId: number, msgStatus: number, content, sendTime: *, sendStatus: (*|number), talkType: number, downStatus: (number|*)}}
   */
  msgToChatMessage (userId, item) {
    if (item === null || isEmpty(item.content)) return null
    if (isEmpty(item.msgId)) item.msgId = uuid()

    const chatId = getChatId(0, userId)
    const talkType = this.getTalkType(item.toUser)
    if (talkType === 0 && chatId !== item.fromUser && item.toUser) {
      // if (item.self) userId = item.fromUser.replace('0-')
      // else userId = item.toUser.replace('0-')
      if (item.self && item.fromUser) userId = item.fromUser.substring(item.fromUser.lastIndexOf('-'), item.fromUser.length)
      else userId = item.toUser.substring(item.toUser.lastIndexOf('-'), item.toUser.length)
    }

    return {
      userId: userId,
      msgId: item.msgId,
      fromUserId: item.fromUser,
      targetId: item.toUser,
      msgStatus: 0,
      msgType: item.msgType,
      content: item.content,
      sendStatus: item.sendStatus != null ? item.sendStatus : 1,
      downStatus: item.downStatus != null ? item.downStatus : 1,
      talkType: talkType,
      addTime: this.dateToTime(item.date),
      sendTime: item.sendTime,
      readTime: 0,
      delete: item.delete === null ? 0 : item.delete,
      ext: JSON.stringify(item.ext)
    }
  }

  /**
   * 本地数据转会话消息
   *
   * @param myChatId
   * @param item
   * @returns {null|{date: Date, ext: any, msgType, fromUser: *, msgId, readTime: (number|*), delete, content, sendTime: *, toUser: *, self: boolean, sendStatus: *, talkType: (number|*), downStatus: (number|*)}}
   */
  chatMessageToMsg (myChatId, item) {
    if (item === null || isEmpty(item.content) || isEmpty(item.msgId)) return null

    return {
      msgId: item.msgId,
      fromUser: item.fromUserId,
      toUser: item.targetId,
      content: item.content,
      msgType: item.msgType,
      sendStatus: item.sendStatus,
      downStatus: item.downStatus,
      talkType: item.talkType,
      date: this.timeToDate(item.addTime),
      sendTime: item.sendTime,
      readTime: item.readTime,
      self: myChatId === item.fromUserId,
      delete: item.delete, // 删除标记
      ext: !isEmpty(item.ext) ? JSON.parse(item.ext) : {}
    }
  }

  /**
   * 远程数据库缓存本地
   *
   * @param userId
   * @param item
   */
  initAppData (userId, msg) {
    const item = this.fomatMessage(userId, msg)

    if (item === null) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    } else {
      return new Promise((resolve, reject) => {
        if (item.msgId != null) {
          this._db.chatMessage
            .where('[userId+msgId]')
            .anyOf([[userId, item.msgId]])
            .toArray()
            .then(
              (result) => {
                if (result === null || result.length <= 0) {
                  this.addChatMessage(item).then(
                    (result) => {
                      resolve(result)
                    },
                    (error) => {
                      console.log(error)
                      reject(error)
                    }
                  )
                }
              },
              (error) => {
                console.log(error)
                reject(error)
              }
            )
        }
      })
    }
  }

  initChatContact (item) {
    // chatContact: "++id, userId, targetId, [userId+targetId], talkType, nickName, name, phone, avatar, addTime, status, remarks, memberIds",
    if (item === null) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    } else {
      return new Promise((resolve, reject) => {
        this._db.chatContact
          .where('[userId+talkType]')
          .anyOf([[item.id, 0]])
          .toArray()
          .then(
            (result) => {
              if (result === null || result.length <= 0) {
                this.updateChatContact(0, item).then(
                  (key) => {
                    resolve(key)
                  },
                  (err) => {
                    console.log(err)
                    reject(err)
                  }
                )
              } else if (result.length > 0 && result[0].addTime < item.modifyTime) {
                this.updateChatContact(result[0].id, item).then(
                  (key) => {
                    resolve(key)
                  },
                  (err) => {
                    console.log(err)
                    reject(err)
                  }
                )
              } else {
                resolve(0)
              }
            },
            (error) => {
              console.log(error)
              reject(error)
            }
          )
      })
    }
  }

  /**
   * 获取用户(群组)信息
   *
   * @param userId
   * @param talkType
   * @returns {*}
   */
  getUserInfo (userId, talkType) {
    return this._db.chatContact
      .where('[userId+talkType]')
      .anyOf([[userId, talkType]])
      .first()
  }

  /**
   * 判断群组成员
   *
   * @param roomName
   * @param userId
   */
  checkGroupMember (roomName, userId) {
    return new Promise((resolve, reject) => {
      try {
        if (isEmpty(roomName) || userId === null) reject(new Error(null))
        else {
          const taskType = parseInt(roomName.substring(0, 1))
          const groupId = parseInt(roomName.substring(2))
          this.getUserInfo(groupId, taskType).then(
            (groupInfo) => {
              const memberArr = getMemberIds(groupInfo.memberIds)
              let found = false
              if (memberArr.indexOf(userId) >= 0) found = true
              if (!found) {
                memberArr.push(userId)
                groupInfo.memberIds = ',' + memberArr.join(',') + ','

                this.updateGroupInfoById(groupInfo).then(
                  (result) => {
                    resolve(1)
                  },
                  (error) => {
                    reject(error)
                  }
                )
              } else {
                resolve(0)
              }
            },
            (error1) => {
              reject(error1)
            }
          )
        }
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })
  }

  /**
   * 查询用户列表
   *
   * @param userIds
   * @param talkType
   * @returns {*}
   */
  findUserByIds (userIds, talkType) {
    const wheres = []
    const arr = userIds.split(',')
    arr.forEach((userId) => {
      if (!isEmpty(userId)) {
        wheres.push([parseInt(userId), talkType])
      }
    })

    return this._db.chatContact.where('[userId+talkType]').anyOf(wheres).toArray()
  }

  /**
   * 获取用户列表
   *
   * @param talkType
   * @returns {*}
   */
  findUserByTalkType (talkType) {
    return this._db.chatContact.where('talkType').equals(talkType).toArray()
  }

  updateChatContact (id, item) {
    if (item === null) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    } else {
      const userInfo = {
        userId: item.id,
        nickName: item.nickName,
        name: item.name,
        phone: item.phone,
        avatar: item.avatar,
        addTime: item.modifyTime,
        status: 0,
        targetId: 0,
        talkType: 0,
        remarks: '',
        memberIds: ''
      }
      if (id > 0) userInfo.id = id

      return this._db.chatContact.put(userInfo)
    }
  }

  initGroupInfo (item) {
    if (item === null) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    } else {
      return new Promise((resolve, reject) => {
        this._db.chatContact
          .where('[userId+talkType]')
          .anyOf([[item.id, item.talkType]])
          .toArray()
          .then(
            (result) => {
              if (result === null || result.length <= 0) {
                this.updateGroupInfo(0, item).then(
                  (key) => {
                    resolve(key)
                  },
                  (err) => {
                    console.log(err)
                    reject(err)
                  }
                )
              } else if (result.length > 0 && result[0].addTime < item.modifyTime) {
                this.updateGroupInfo(result[0].id, item).then(
                  (key) => {
                    resolve(key)
                  },
                  (err) => {
                    console.log(err)
                    reject(err)
                  }
                )
              } else {
                resolve(0)
              }
            },
            (error) => {
              console.log(error)
              reject(error)
            }
          )
      })
    }
  }

  /**
   * 更新群组消息
   *
   * @param id
   * @param item
   * @returns {Promise<unknown>|*}
   */
  updateGroupInfo (id, item) {
    if (item === null) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    } else {
      const groupInfo = {
        userId: item.id,
        nickName: item.groupName,
        name: item.groupName,
        phone: '',
        avatar: item.avatar,
        addTime: item.createTime,
        status: 0,
        targetId: item.userId,
        talkType: item.talkType,
        remarks: item.remarks,
        memberIds: isEmpty(item.memberIds) ? '' : ',' + item.memberIds + ','
      }
      if (id > 0) groupInfo.id = id

      return this._db.chatContact.put(groupInfo)
    }
  }

  updateGroupInfoById (groupInfo) {
    console.log('================updateGroupInfoById==================')
    console.log(groupInfo)

    if (groupInfo === null) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    } else {
      return this._db.chatContact.put(groupInfo)
    }
  }

  /**
   * 远程数据格式化本地数据
   *
   * @param myChatId
   * @param item
   * @returns {null|{date: Date, ext: any, msgType, fromUser: *, msgId, readTime: (number|*), delete, content, sendTime: *, toUser: *, self: boolean, sendStatus: *, talkType: (number|*), downStatus: (number|*)}}
   */
  fomatMessage (userId, item) {
    if (item === null || isEmpty(item.content) || isEmpty(item.msgId)) return null

    const sizeInfo = this.formatFileSize(item.size)

    return {
      userId: userId,
      msgId: item.msgId,
      fromUserId: getChatId('0', item.fromUserId),
      targetId: getChatId(item.talkType, item.targetId),
      msgStatus: item.msgStatus,
      msgType: this.toMsgType(item.msgType),
      content: item.content,
      sendStatus: 1,
      downStatus: this.getDownStatus(item.msgType),
      talkType: item.talkType,
      addTime: item.sendTime,
      sendTime: item.sendTime,
      readTime: item.readTime,
      delete: 0,
      ext: JSON.stringify({
        fileId: item.fileId === null ? 0 : item.fileId,
        totalBytes: item.fileSize,
        received: 0,
        complete: '100%',
        lastTime: item.sendTime,
        fileSize: sizeInfo.fileSize,
        fileSizeUnit: sizeInfo.fileSizeUnit,
        fileExt: this.getFileExt(item.content),
        fileUrl: item.fileUrl,
        srcFile: item.content,
        srcFileUrl: null,
        downTotal: 1,
        downReceived: 1,
        downComplete: 0,
        downTime: 0,
        base64: null
      })
    }
  }

  /**
   * 远程数据格式化本地存储
   *
   * @param userId
   * @param item
   * @returns {null|{date: Date, ext: {lastTime: *, downTime: number, srcFileUrl: null, downComplete: number, fileSizeUnit: string, base64: null, srcFile, received: number, fileExt: (string|string), downTotal: number, fileSize: number, totalBytes: (number|Ref<number>|number|*), fileUrl: (null|*), complete: string, downReceived: number, fileId: (number|*)}, msgType: string, fromUser, msgId, readTime: (number|*), delete: number, content, sendTime: *, toUser, self: boolean, sendStatus: number, talkType: (number|*), downStatus: number}}
   */
  formatStoreMsg (userId, item) {
    if (item === null || isEmpty(item.content) || isEmpty(item.msgId)) return null
    const sizeInfo = this.formatFileSize(item.size)

    return {
      msgId: item.msgId,
      fromUser: getChatId('0', item.fromUserId),
      toUser: getChatId(item.talkType, item.targetId),
      content: item.content,
      msgType: this.toMsgType(item.msgType),
      sendStatus: 1,
      downStatus: this.getDownStatus(item.msgType),
      talkType: item.talkType,
      date: this.timeToDate(item.sendTime),
      sendTime: item.sendTime,
      readTime: item.readTime,
      self: userId === parseInt(item.fromUserId),
      delete: 0, // 删除标记
      isShow: this.isShow(item.msgType),
      ext: {
        fileId: item.fileId === null ? 0 : item.fileId,
        totalBytes: item.fileSize,
        received: 0,
        complete: '100%',
        lastTime: item.sendTime,
        fileSize: sizeInfo.fileSize,
        fileSizeUnit: sizeInfo.fileSizeUnit,
        fileExt: item.fileId === null || item.fileId === 0 ? '' : this.getFileExt(item.content),
        fileUrl: item.fileUrl,
        srcFile: item.content,
        srcFileUrl: null,
        downTotal: 1,
        downReceived: 1,
        downComplete: 0,
        downTime: 0,
        base64: null
      }
    }
  }

  /**
   * 格式化文件大小
   *
   * @param totalBytes
   * @returns {{fileSize: number, fileSizeUnit: string}}
   */
  formatFileSize (totalBytes) {
    let fileSizeUnit = 'B'

    let fileSize = totalBytes

    if (fileSize > 100) {
      fileSize = fileSize / 1024
      fileSizeUnit = 'KB'
      if (fileSize > 100) {
        fileSize = fileSize / 1024
        fileSizeUnit = 'MB'
      }
    }

    return { fileSize: fileSize, fileSizeUnit: fileSizeUnit }
  }

  /**
   * 获取下载状态
   *
   * @param msgType
   * @returns {number}
   */
  getDownStatus (msgType) {
    let downStatus = 1
    switch (msgType) {
      case 3:
      case 5:
      case 6:
        downStatus = 0
        break

      case 10:
      case 1:
      default:
        downStatus = 1
        break
    }

    return downStatus
  }

  /**
   * 获取文件扩展名
   *
   * @param fileName
   * @returns {string}
   */
  getFileExt (fileName) {
    if (isEmpty(fileName)) return ''
    let extName = fileName.toLowerCase().substring(fileName.lastIndexOf('.'))
    if (extName === null) extName = ''

    return extName
  }

  /**
   * 远程数据类型转本地消息类型
   *
   * @param type
   * @returns {string}
   */
  toMsgType (type) {
    let msgType = ''

    switch (type) {
      case 3:
        msgType = 'img'
        break
      case 5:
        msgType = 'file'
        break
      case 6:
        msgType = 'video'
        break
      case 10:
        msgType = 'recall'
        break
      case 1:
        msgType = 'text'
        break
      case 2: // 语音长度？
      case 9: // 加好友提示信息
      case 12:
      case 80:
      case 124: // 音视频通话指令
      case 402: // 音视频通话指令
      default:
        msgType = type + ''
        break
    }

    return msgType
  }

  isShow (type) {
    let show = false
    switch (type) {
      case 3:
      case 5:
      case 6:
      case 10:
      case 1:
        show = true
        break
      case 2: // 语音长度？
      case 9: // 加好友提示信息
      case 12:
      case 80:
      case 124: // 音视频通话指令
      case 402: // 音视频通话指令
      default:
        break
    }

    return show
  }

  /**
   * 查询、更新、删除测试
   *
   * @returns {Promise<void>}
   */
  async testSql () {
    const list = this._db.chatMessage
      .where('msgId')
      .equals('5ede27fe7e324a6e8d721c1ec3cdd01d')
      .and((item) => item.talkType === 0) // .and(item => item.id === 80);
    list.each((r) => {
      console.log(r)
    })

    this._db.chatMessage
      .where({ msgId: '045d98de66974c788d46fec657072190' })
      .toArray()
      .then((result) => {
        console.log(result)
      })

    const arr = await this._db.chatMessage
      .where({ msgId: '045d98de66974c788d46fec657072190' })
      .toArray()
    this.addChatMessage(arr[0]).then((result) => {
      console.log('============addChatMessage==========')
      console.log(result)
    })

    this._db.chatMessage.get(1).then(
      (res) => {
        console.log(res)
      },
      (error) => {
        console.log(error)
      }
    )
  }
}
