export default {
  name: 'svg-icon',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconPath() {
      let icon = `@/assets/icons/${this.name}.svg`;
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }
      return icon.url;
    },
    className() {
      return 'svg-icon svg-icon--' + this.name;
    }
  }
};