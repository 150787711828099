import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from 'vuex';
import Notify from '@wcjiang/notify';
import bus from '@/utils/bus';
import { isEmpty } from '@/utils/StringUtils';
export default {
  name: 'App',
  data() {
    return {
      notify: null
    };
  },
  created() {
    const that = this;
    window.unlockScreen = function () {
      that.checkWs();
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log('==============App vue callback=================');
      // 全局回调
      this.$global.setCallback(99, this.callbacks);
      this.initNotify();
    });
  },
  watch: {
    getNotifyNum: {
      handler(newVal, oldVal) {
        if (newVal > 0) this.playNotify(newVal);
      }
    },
    '$router.currentRoute.value': {
      handler(newVal, oldVal) {
        console.log('newVal=');
        console.log(newVal);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['getNotifyNum'])
  },
  methods: {
    ...mapActions(['setAutoLogin', 'setNotifyNum']),
    checkWs() {
      const userInfo = this.$store.getters.getUserInfo;
      if (userInfo != null) this.$global.reconnect(userInfo, this.$store);
    },
    callbacks(json) {
      switch (json.type) {
        case 'kickLogin':
          this.logout();
          break;
        case 'onCall':
          // chat.vue呼叫
          bus.emit('pushChatMsg', json);
          break;
        case 'onMeetingCall':
          // meeting.vue呼叫
          bus.emit('pushMeetingMsg', json);
          break;
        case 'notify':
          this.parseNotify(json);
          break;
        default:
          break;
      }
    },
    // 解析notify消息
    parseNotify(json) {
      const routeInfo = this.$router.currentRoute.value;
      switch (json.msgType) {
        case 17:
          if (isEmpty(routeInfo.path) || routeInfo.path !== '/chat') {
            this.changeRoute('/chat', json);
          } else {
            bus.emit('pushChatMsg', json);
          }
          break;
        case 20:
          // 用户离线
          bus.emit('pushChatMsg', json);
          bus.emit('pushMeetingMsg', json);
          bus.emit('pushVideoMeetingMsg', json);
          bus.emit('pushShareScreenMsg', json);
          break;
        case 21:
          if (isEmpty(routeInfo.path) || routeInfo.path !== '/meeting') {
            this.changeRoute('/meeting', json);
          } else {
            bus.emit('pushMeetingMsg', json);
          }
          break;
        case 22:
          if (isEmpty(routeInfo.path) || routeInfo.path !== '/shareScreen') {
            this.changeRoute('/shareScreen', json);
          } else {
            bus.emit('pushShareScreenMsg', json);
          }
          break;
        default:
          break;
      }
    },
    // 改变路由
    changeRoute(path, json) {
      this.$router.push({
        path: path,
        query: {
          data: JSON.stringify(json)
        }
      });
    },
    // 消息初始化
    initNotify() {
      const that = this;
      this.notify = new Notify({
        message: '有消息了。',
        // 标题
        effect: 'flash',
        // flash | scroll, 闪烁还是滚动
        openurl: 'https://kj.isuger.com.cn/',
        // Click on the pop-up window to open the connection address
        onclick: () => {
          // Click on the pop-up window trip event
          // Programmatically closes a notification.
          that.notify.close();
          console.log('---');
        },
        // 可选播放声音
        audio: {
          // 可以使用数组传多种格式的声音文件
          file: ['/static/audio/notify.mp3', '/static/audio/notify.wav']
          // 下面也是可以的哦
          // file: '/static/audio/notify.mp3'
        },

        // 标题闪烁，或者滚动速度
        interval: 1000,
        disableFavicon: false,
        // Optional, default false, if true, No longer overwrites the original favicon
        // 可选，默认绿底白字的  Favicon
        updateFavicon: {
          // favicon 字体颜色
          textColor: '#fff',
          // 背景颜色，设置背景颜色透明，将值设置为“transparent”
          backgroundColor: '#2F9A00'
        },
        // 可选chrome浏览器通知，默认不填写就是下面的内容
        notification: {
          title: '通知！',
          // 设置标题
          icon: '',
          // 设置图标 icon 默认为 Favicon
          body: '您来了一条新消息' // 设置消息内容
        }
      });
    },

    playNotify(num) {
      console.log('==================playNotify==================');
      console.log(num);
      if (num <= 0) return;
      this.setNotifyNum(0);
      if (this.notify != null && this.notify.isPermission()) this.notify.player();
    },
    // 注销
    logout() {
      this.$store.dispatch('setName', null);
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUserInfo', null);
      this.$global.close();
      this.setAutoLogin('0');
      this.$router.push('/login');
    }
  }
};