import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    name: 'itemmessages',
    path: '/itemmessages',
    component: () => import('@/views/h5/home/itemmessages'),
    meta: { tag: '消息', title: '消息' }
  },
  {
    name: 'UploaderImages',
    path: '/UploaderImages',
    component: () => import('@/views/h5/home/UploaderImages'),
    meta: { tag: '选择图片', title: '选择图片' }
  },
  {
    name: 'iteminfo',
    path: '/iteminfo',
    component: () => import('@/views/h5/contacts/itemInfo'),
    meta: { tag: '详情', title: '详情' }
  },
  {
    name: 'adduser',
    path: '/adduser',
    component: () => import('@/views/h5/contacts/addUser'),
    meta: { tag: '添加好友', title: '添加好友' }
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('@/views/h5/components/search'),
    meta: { tag: '添加好友', title: '添加好友' }
  }, 

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: { tag: '登录', title: '登录' }
  },
  {
    path: '/',
    component: () => import('@/views/h5/index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/h5/home/index.vue'),
        meta: { tag: '消息', title: '消息' }
      },
      {
        name: 'contacts',
        path: '/contacts',
        component: () => import('@/views/h5/contacts/index.vue'),
        meta: {
          tag: '通讯录',
          title: '通讯录'
          // isLogin: true
        }
      },
      {
        name: 'friends',
        path: '/friends',
        component: () => import('@/views/h5/friends/index.vue'),
        meta: {
          tag: '发现',
          title: '发现'
          // isLogin: true
        }
      },
      {
        name: 'setting',
        path: '/setting',
        component: () => import('@/views/h5/setting/index.vue'),
        meta: {
          tag: '我的',
          title: '我的'
          // isLogin: true
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }

  const token = sessionStorage.getItem('token')
  if (!token && !['/login', '/other'].includes(to.path)) {
    next('/login')
  } else {
    next()
  }
})
export default router
